import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { RootState } from "../types";
import { TradesState } from "./types";
import { mutations } from "./mutations";

export const state: TradesState = {
  isLoading: false,
  trades: [],
  tradeDetails: [],
  tradesFlags: {},
};

const namespaced = true;

export const trades: Module<TradesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
