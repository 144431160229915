















import { ITrade } from "@/store/offers";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TradeItemDialogCancel",
  components: {},
})
export default class TradeItemDialogCancel extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;

  dialog = true;

  no() {
    this.$emit("no");
  }
  yes() {
    this.$emit("yes");
  }
}
