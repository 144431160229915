


































import { Getter } from "vuex-class";
import TokenListImport from "./TokenListImport.vue";
import TokenListsSelect from "./TokenListsSelect.vue";
import { Component, Vue } from "vue-property-decorator";
import { ITokenList } from "@/store/tradeLists/tokenLists";
@Component({
  components: {
    TokenListImport,
    TokenListsSelect,
  },
})
export default class TokenListsDialog extends Vue {
  dialog = true;
  selected: Array<ITokenList> = [];
  @Getter("lists", { namespace: "lists" }) lists!: Array<ITokenList>;

  close() {
    this.$emit("close");
  }
}
