



































import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { ITokenList } from "@/store/tradeLists/tokenLists";
@Component({
  components: {
    //
  },
})
export default class TokenListsSelect extends Vue {
  selected: Array<ITokenList> = [];
  @Getter("lists", { namespace: "tokens" }) lists!: Array<ITokenList>;
  @Action("setTokenListIsActive", { namespace: "tokens" })
  setTokenListIsActive!: (payload: { id: number; val: boolean }) => any;

  isActiveToggle(list: ITokenList) {
    if (!list.id) return;
    this.setTokenListIsActive({
      id: list.id,
      val: !list.isActive,
    });
  }
}
