import Vue from "vue";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { TransactionsState, ITransaction, ITransactionUpdate } from "./types";

export const actions: ActionTree<TransactionsState, RootState> = {
  setTransactions(
    { commit, state },
    payload: Array<ITransaction> | undefined
  ): Array<ITransaction> | undefined {
    commit("setTransactions", payload);
    return payload;
  },
  addTransaction({ commit, state }, payload: ITransaction): ITransaction {
    Vue.nextTick(() => {
      commit("addTransaction", payload);
    });
    return payload;
  },
  updateTransaction(
    { commit, state },
    payload: ITransactionUpdate
  ): ITransaction {
    commit("updateTransaction", payload);
    return payload.transaction;
  },
};
