import { ActionTree } from "vuex";
import { RootState } from "../types";
import { ChainsState, ChainSwitcherStatus } from "./types";

export const actions: ActionTree<ChainsState, RootState> = {
  setChainSwitcherStatus(
    { commit, state },
    status: ChainSwitcherStatus
  ): ChainSwitcherStatus {
    commit("setChainSwitcherStatus", status);
    return status;
  },
};
