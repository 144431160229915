import {
  CHAIN_ID_GOERLI,
  CHAIN_ID_MAINNET,
  CHAIN_ID_POLYGON,
  CHAIN_ID_POLYGON_MUMBAI,
} from "../chains";
import store from "../index";
import { BigNumber } from "bignumber.js";
import { Alchemy, Network } from "alchemy-sdk";
import chainHelper from "@/helpers/chain.helper";
import { ADDRESS_NULL } from "@/helpers/constants";
import { IToken, ITokenAndAmount } from "../tokens";
import { ERC20Helper } from "@/helpers/erc20.helper";
import ethereumHelper from "@/helpers/ethereum.helper";
import ERC20AmountHelpers from "@/helpers/erc20Amounts.helper";

/**
 * User Tokens Helper
 */
export class UserTokensHelper {
  /**
   * Store Access
   */
  public get storeTokens(): Array<ITokenAndAmount> {
    return store.getters["userTokens/tokens"] ?? [];
  }
  // async storeTokensFetch(payload: ITokenAndAmount) {
  //   await store.dispatch("userTokens/tokensFetch", payload);
  // }
  async storeTokensSet(payload: Array<ITokenAndAmount>) {
    await store.dispatch("userTokens/tokensSet", payload);
  }
  async storeTokenAdd(payload: ITokenAndAmount) {
    store.dispatch("userTokens/tokenAdd", payload);
  }
  async storeTokenUpdate(payload: ITokenAndAmount) {
    store.dispatch("userTokens/tokenUpdate", payload);
  }

  /**
   * Methods
   */

  /**
   * Find token and amount of the User balance
   */
  getUserTokenAndAmount(token: IToken) {
    return this.storeTokens.find((userTokenAndAmount: ITokenAndAmount) => {
      return userTokenAndAmount.token?.symbol === token?.symbol;
    });
  }

  /**
   * Update User Token Amount
   */
  updateUserTokenAmount(token: IToken, amount: number | undefined) {
    const found = this.getUserTokenAndAmount(token);
    if (!found) {
      this.storeTokenAdd({
        token,
        amount,
      });
    } else {
      const res = this.storeTokens.map((val) => {
        if (val.token?.symbol === token.symbol) {
          val.amount = amount;
        }
        return val;
      });
      this.storeTokensSet(res);
    }
  }

  /**
   * Has Enough Balance for the Token
   */
  hasEnough(tokenAndAmountNeeded: ITokenAndAmount): boolean {
    if (!tokenAndAmountNeeded.token) return false;
    const userTokenAndAmount = this.getUserTokenAndAmount(
      tokenAndAmountNeeded.token
    );
    if (!userTokenAndAmount) return false;
    return (
      (tokenAndAmountNeeded.amount ?? 0) <= (userTokenAndAmount.amount ?? 0)
    );
  }

  /**
   * Fetch User Token Amount
   */
  async fetchUserTokenAmount(
    token: IToken,
    userAddress?: string
  ): Promise<number | undefined> {
    let amount: BigNumber | undefined;

    // Get amount
    if (token.address === ADDRESS_NULL) {
      amount = await ethereumHelper.getBalance(userAddress);
    } else if (token.address) {
      try {
        const erc20Helper = new ERC20Helper(token.address, userAddress);
        amount = new BigNumber((await erc20Helper.getUserBalance()) ?? 0);
      } catch (error) {
        //
      }
    }

    if (amount) {
      // Take Decimals into account
      amount = ERC20AmountHelpers.transformAmountToTokensBasedOnDecimals(
        amount,
        token.decimals
      );
    }

    const amountFinal: number | undefined = amount
      ? parseFloat(amount.toFixed(9))
      : undefined;
    // Update, Set
    this.updateUserTokenAmount(token, amountFinal);
    // Return
    return amountFinal;
  }

  /**
   * Fetch User Tokens and Amount
   */
  async fetchUserTokensAndAmount(userAddress?: string): Promise<void> {
    const tokens: Array<IToken> = store.getters["tokens/tokens"]() ?? [];
    await this.fetchUserTokensAndAmountFromAlchemy(userAddress, tokens);
    return Promise.resolve();
  }

  /**
   * Fetch User Tokens and Amount From Alchemy
   */
  async fetchUserTokensAndAmountFromAlchemy(
    userAddress?: string,
    tokens?: Array<IToken>
  ): Promise<void> {
    if (!userAddress) return;

    /**
     * Alchemy config
     *
     * Use different app for diff chain (separate API key).
     */
    let network;
    let apiKey;
    switch (chainHelper.network?.chainId) {
      case CHAIN_ID_MAINNET:
        network = Network.ETH_MAINNET;
        apiKey = process.env.VUE_APP_ALCHEMY_MAINET_API_KEY;
        break;
      case CHAIN_ID_POLYGON:
        network = Network.MATIC_MAINNET;
        apiKey = process.env.VUE_APP_ALCHEMY_POLYGON_API_KEY;
        break;
      case CHAIN_ID_GOERLI:
        network = Network.ETH_GOERLI;
        apiKey = process.env.VUE_APP_ALCHEMY_GOERLI_API_KEY;
        break;
      case CHAIN_ID_POLYGON_MUMBAI:
        network = Network.MATIC_MUMBAI;
        apiKey = process.env.VUE_APP_ALCHEMY_POLYGON_MUMBAI_API_KEY;
        break;
    }
    const alchemy = new Alchemy({
      apiKey,
      network,
    });

    /**
     * Get token address list
     */
    const tokenAddressList: Array<string> = [];
    tokens?.forEach((token) => {
      tokenAddressList.push(token.address);
    });

    /**
     * Fetch token balances
     */
    const balances = await alchemy.core.getTokenBalances(
      userAddress,
      tokenAddressList && tokenAddressList.length ? tokenAddressList : undefined
    );

    /**
     * Remove tokens with zero balance
     */
    const nonZeroBalances = balances.tokenBalances.filter((token) => {
      return (
        token.tokenBalance !==
          "0x0000000000000000000000000000000000000000000000000000000000000000" &&
        token.tokenBalance != "0x"
      );
    });

    /**
     * Fetch exact token balance
     * For all tokens with non-zero balance
     */
    for (const alchemyToken of nonZeroBalances) {
      const token = tokens?.find(
        (item) => item.address === alchemyToken.contractAddress
      );
      if (token) {
        await this.fetchUserTokenAmount(token, userAddress);
      }
    }

    /**
     * Fetch NativeCurrency Token
     * (ETH, MATIC, ...)
     * (Token with NULL address)
     */
    const nativeCurrencyToken = tokens?.find(
      (item) => item.address === ADDRESS_NULL
    );
    if (nativeCurrencyToken) {
      await this.fetchUserTokenAmount(nativeCurrencyToken, userAddress);
    }

    return Promise.resolve();
  }
}

export default new UserTokensHelper();
