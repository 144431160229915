import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { RootState } from "../types";
import { UserTokensState } from "./types";
import { mutations } from "./mutations";

export const state: UserTokensState = {
  isLoading: false,
  tokens: [],
};

const namespaced = true;

export const userTokens: Module<UserTokensState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
