







































import { IToken } from "@/store/tokens";
import EthereumHelper from "@/helpers/ethereum.helper";
import UserTokensHelper from "@/store/userTokens/userTokens.helper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class TokenBox extends Vue {
  @Prop({ type: Object, required: true, default: undefined })
  token!: IToken;
  @Prop({ type: Boolean, required: false, default: false })
  isNameVisible!: boolean;
  @Prop({ type: Number, required: false, default: 64 })
  size!: number;
  @Prop({ type: Boolean, required: false, default: true })
  isEditable!: boolean;
  @Prop({ type: Boolean, required: false, default: true })
  isBalanceVisible!: boolean;

  balance: number | null = null;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  get signerAddress() {
    return this.ethereumHelper.signerAddress;
  }

  /**
   * Fetch Balance
   */

  @Watch("token", {
    deep: true,
    immediate: true,
  })
  tokenChanged() {
    this.fetchUserTokenBalance();
  }
  @Watch("signerAddress", {
    deep: true,
    immediate: false,
  })
  signerAddressChanged() {
    this.fetchUserTokenBalance();
  }
  async fetchUserTokenBalance() {
    if (this.signerAddress) {
      const balance = await UserTokensHelper.fetchUserTokenAmount(
        this.token,
        this.signerAddress
      );
      this.balance = balance ?? null;
    }
  }

  edit() {
    this.$emit("edit");
  }
  remove() {
    this.$emit("remove");
  }

  get name(): string {
    return this.token.symbol ?? this.token.name ?? "N/A";
  }
  get imageUrl() {
    if (this.isIpfsImage)
      return "https://gateway.ipfs.io/ipfs/" + this.token.logoURI.slice(7);
    return this.token.logoURI ?? "/img/tokens/erc201.png";
  }
  get isIpfsImage() {
    const prefix = this.token.logoURI?.substring(0, 7);
    if (prefix === "ipfs://") return true;
    return false;
  }
}
