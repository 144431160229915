import store from "@/store";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import chainHelper from "./chain.helper";
import { TransactionStatus } from "@/store/transactions/types";

/**
 * Contract Interface
 */
export const contractAbi = [
  `function name() view returns (string)`,
  `function symbol() view returns (string)`,
  `function decimals() view returns (uint8)`,
  `function balanceOf(address account) external view returns (uint256)`,
  `function approve(address delegate, uint256 numTokens) public returns (bool)`,
];

/**
 * ERC20 Helper
 */
export class ERC20Helper {
  public contract?: ethers.Contract;
  public provider?: ethers.providers.Web3Provider;

  public userAddress?: string;
  public signer?: ethers.providers.JsonRpcSigner;

  constructor(contractAddress: string, userAddress?: string) {
    if (window.ethereum) {
      // Provider
      this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");

      /**
       * Address or Signer
       */
      if (userAddress) {
        this.userAddress = userAddress;
      } else {
        this.signer = this.provider?.getSigner();
      }

      // Contract
      if (this.provider || this.signer) {
        this.contract = new ethers.Contract(
          contractAddress,
          contractAbi,
          this.signer ?? this.provider
        );
      }
    }
  }

  /**
   * getName
   *
   * @returns {Promise<string>}
   */
  async getName(): Promise<string> {
    if (!this.contract) throw Error("Contract not initialized");
    const value = await this.contract.name();
    return value.toString();
  }

  /**
   * getSymbol
   *
   * @returns {Promise<string>}
   */
  async getSymbol(): Promise<string> {
    if (!this.contract) throw Error("Contract not initialized");
    const value = await this.contract.symbol();
    return value.toString();
  }

  /**
   * getDecimals
   *
   * @returns {Promise<number>}
   */
  async getDecimals(): Promise<number> {
    if (!this.contract) throw Error("Contract not initialized");
    const value = await this.contract.decimals();
    return value;
  }

  /**
   * getUserBalance
   *
   * @returns {Promise<string|undefined>}
   */
  async getUserBalance(): Promise<string | undefined> {
    if (!this.contract) return;
    let address = null;
    if (this.userAddress) {
      address = this.userAddress;
    } else if (this.signer) {
      address = await this.signer?.getAddress();
    } else {
      return undefined;
    }

    const value = await this.contract.balanceOf(address);
    return value.toString();
  }

  /**
   * Approve
   *
   * @returns {Promise<string|undefined>}
   */
  async approve(amount: BigNumber): Promise<string | undefined> {
    if (!this.contract) throw "No contract initialized";
    if (!this.signer) throw "No signer initialized";

    if (!chainHelper.network) throw Error("No network");
    const deployment = chainHelper.getContractDeploymentPerChain(
      chainHelper.network.chainId
    );
    if (!deployment) throw Error("No address or abi for deployment");

    await store.dispatch("transactions/addTransaction", {
      contractAddress: this.contract.address,
      contractIsErc20: true,
      status: TransactionStatus.APPROVE_REQUIRED,
    });

    let tx;
    try {
      tx = await this.contract.approve(deployment.address, amount.toFixed());
      await store.dispatch("transactions/updateTransaction", {
        contractAddress: this.contract.address,
        transaction: {
          status: TransactionStatus.APPROVE_DONE,
        },
      });
    } catch (error) {
      console.log("--ERC20-Approve", error);

      await store.dispatch("transactions/updateTransaction", {
        contractAddress: this.contract.address,
        transaction: {
          status: TransactionStatus.APPROVE_REJECTED,
        },
      });
      throw error;
    }

    /**
     * Wait for confirmation
     */
    await store.dispatch("transactions/updateTransaction", {
      contractAddress: this.contract.address,
      transaction: {
        status: TransactionStatus.MINING,
      },
    });
    const rc = await tx.wait();
    await store.dispatch("transactions/updateTransaction", {
      contractAddress: this.contract.address,
      transaction: {
        status: TransactionStatus.MINED,
      },
    });

    return undefined;
  }
}

export default ERC20Helper;
