import { MutationTree } from "vuex";
import {
  INotificationItem,
  INotificationItemData,
} from "@/helpers/notification.helper";
import { NotificationsState } from "./types";

export const mutations: MutationTree<NotificationsState> = {
  setNotifications(state, payload: Array<INotificationItem> | undefined) {
    state.notifications = payload;
  },
  addNotification(state, payload: INotificationItem) {
    state.notifications = [...(state.notifications ?? []), payload];
  },
  updateNotification(state, payload: { id: number; data: INotificationItem }) {
    state.notifications = state.notifications?.map((item) => {
      if (item.data?.id === payload.id) {
        return {
          ...item,
          ...payload.data,
        };
      }
      return item;
    });
  },
  updateNotificationData(
    state,
    payload: { id: number; data: INotificationItemData }
  ) {
    state.notifications = state.notifications?.map((item) => {
      if (item.data?.id === payload.id) {
        item.data = {
          ...item.data,
          ...payload.data,
        };
        return item;
      }
      return item;
    });
  },
};
