import { GetterTree } from "vuex";
import { RootState } from "../types";
import { TransactionsState, ITransaction } from "./types";

export const getters: GetterTree<TransactionsState, RootState> = {
  transactions(state): Array<ITransaction> | undefined {
    const { transactions } = state;
    return transactions;
  },
  transaction:
    (state) =>
    (contractAddress: string): ITransaction | undefined => {
      const { transactions } = state;
      return transactions?.find(
        (item) => item.contractAddress === contractAddress
      );
    },
};
