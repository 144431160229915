import {
  TradesState,
  TradeUpdateDto,
  TradeIsAcceptPendingDto,
  TradeIsCancelPendingDto,
} from "./types";
import { ITrade, ITradeDetails } from "../offers";
import { MutationTree } from "vuex";
import Vue from "vue";

export const mutations: MutationTree<TradesState> = {
  setIsLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  setTradeDetails(state, payload: Array<ITradeDetails> | undefined) {
    state.tradeDetails = payload ? [...payload] : undefined;
  },
  setTrades(state, payload: Array<ITrade> | undefined) {
    state.trades = payload;
  },
  tradeUpdate(state, payload: TradeUpdateDto) {
    if (!state.trades) return;
    state.trades = state.trades?.map((trade: ITrade) => {
      if (payload.tradeID === trade.tradeID) {
        return {
          ...trade,
          ...payload.trade,
        };
      }
      return trade;
    });
  },
  /**
   * Flags
   */
  setTradeIsAcceptPending(state, payload: TradeIsAcceptPendingDto) {
    const previous = state.tradesFlags[payload.tradeID.toString()] ?? {};
    Vue.set(state, "tradesFlags", {
      ...state.tradesFlags,
      [payload.tradeID.toString()]: {
        ...previous,
        isAcceptPending: payload.isAcceptPending,
      },
    });
  },
  setTradeIsCancelPending(state, payload: TradeIsCancelPendingDto) {
    const previous = state.tradesFlags[payload.tradeID.toString()] ?? {};
    Vue.set(state, "tradesFlags", {
      ...state.tradesFlags,
      [payload.tradeID.toString()]: {
        ...previous,
        isCancelPending: payload.isCancelPending,
      },
    });
  },
};
