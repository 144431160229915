import { GetterTree } from "vuex";
import { IToken } from "../tokens";
import { RootState } from "../types";
import { TokensState } from "./types";
import chainHelper from "../../helpers/chain.helper";
import { ITokenList } from "../tradeLists/tokenLists";

export const getters: GetterTree<TokensState, RootState> = {
  isLoading(state): boolean | undefined {
    const { isLoading } = state;
    return isLoading;
  },
  lists(state): Array<ITokenList> | undefined {
    const { lists } = state;
    return lists;
  },
  list:
    (state, getters) =>
    (id: number): ITokenList | undefined => {
      const lists = getters["lists"];
      if (!lists) return undefined;
      return lists.find((item: ITokenList) => {
        return item.id === id;
      });
    },
  userList(state, getters): ITokenList | undefined {
    const lists = getters["lists"];
    if (!lists) return undefined;
    return lists.find((item: ITokenList) => {
      return item.isUserList;
    });
  },
  /**
   * TODO: Pass optional chainId param
   */
  tokens:
    (state, getters) =>
    (onlyActiveLists = true): Array<IToken> | undefined => {
      const lists = getters["lists"];
      if (!lists) return undefined;

      if (!chainHelper.network) throw Error("Network not selected");
      const chainId = chainHelper.network.chainId;
      let tokens: Array<IToken> = [];
      for (const list of lists) {
        if (!onlyActiveLists || list.isActive) {
          tokens = [
            ...tokens,
            ...list.tokens.filter((token: IToken) => {
              // return true;
              return chainId ? chainId === token.chainId : true;
            }),
          ];
        }
      }
      return tokens;
    },
  getTokenByAddress:
    (state, getters) =>
    (address: string): IToken | undefined => {
      const tokens = getters["tokens"](true);
      return tokens.find((token: IToken) => {
        return token.address === address;
      });
    },
};
