import { MutationTree } from "vuex";
import { ITransaction, ITransactionUpdate, TransactionsState } from "./types";

export const mutations: MutationTree<TransactionsState> = {
  setTransactions(state, payload: Array<ITransaction> | undefined) {
    state.transactions = [...(payload ?? [])];
  },
  addTransaction(state, payload: ITransaction) {
    state.transactions = [...(state.transactions ?? []), payload];
  },
  updateTransaction(state, payload: ITransactionUpdate) {
    state.transactions = state.transactions?.map((item) => {
      if (item.contractAddress === payload.contractAddress) {
        return {
          ...item,
          ...payload.transaction,
        };
      }
      return item;
    });
  },
};
