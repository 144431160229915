










































import { ITool } from "@/store/tools";
import { IToken } from "@/store/tokens";
import { ITokenAndAmount } from "@/store/tokens";
import TradeCreateOptional from "./TradeCreateOptional.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TradeCreateRequiredSide from "./TradeCreateRequiredSide.vue";
import { TradeTokenSide, ITradeCreateRequiredSubmit } from "@/store/trades";
@Component({
  components: {
    TradeCreateOptional,
    TradeCreateRequiredSide,
  },
})
export default class TradeCreateRequired extends Vue {
  @Prop({ type: Array, required: true, default: undefined })
  tokens!: Array<IToken>;
  @Prop({ type: Array, required: true, default: undefined })
  tools!: Array<ITool>;
  @Prop({ type: Array, required: false, default: undefined })
  give!: Array<ITokenAndAmount>;
  @Prop({ type: Array, required: false, default: undefined })
  receive!: Array<ITokenAndAmount>;
  @Prop({ type: Boolean, required: false, default: true })
  isEditable!: boolean;

  data: ITradeCreateRequiredSubmit = {};
  giveTokens: Array<ITokenAndAmount> = [];
  receiveTokens: Array<ITokenAndAmount> = [];
  get tradeTokenSide(): typeof TradeTokenSide {
    return TradeTokenSide;
  }
  get tokenInitialGive(): IToken | undefined {
    return this.tokens && this.tokens.length ? this.tokens[0] : undefined;
  }
  get tokenInitialReceive(): IToken | undefined {
    return this.tokens && this.tokens.length > 0 ? this.tokens[1] : undefined;
  }

  @Watch("give", {
    deep: true,
    immediate: true,
  })
  giveChanged() {
    this.setGive(this.give);
  }
  @Watch("receive", {
    deep: true,
    immediate: true,
  })
  receiveChanged() {
    this.setReceive(this.receive);
  }
  @Watch("giveTokens", {
    deep: true,
    immediate: false,
  })
  giveTokensChanged() {
    this.submit();
  }
  @Watch("receiveTokens", {
    deep: true,
    immediate: false,
  })
  receiveTokensChanged() {
    this.submit();
  }

  setGive(data: Array<ITokenAndAmount>) {
    this.$set(this, "giveTokens", data);
  }
  setReceive(data: Array<ITokenAndAmount>) {
    this.$set(this, "receiveTokens", data);
  }
  swapSides() {
    // Disabled.
    return;
    // if (!this.isEditable) return;
    // const tmpGive = [...this.giveTokens];
    // this.setGive(this.receiveTokens);
    // this.setReceive(tmpGive);
  }
  submit() {
    const data: ITradeCreateRequiredSubmit = {
      giveTokens: this.giveTokens,
      receiveTokens: this.receiveTokens,
    };
    this.$emit("submit", data);
  }
}
