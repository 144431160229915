

























import UserAvatar from "./UserAvatar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {
    UserAvatar,
  },
})
export default class UserHeader extends Vue {
  @Prop({ type: String, default: undefined, required: true })
  name!: string;
  @Prop({ type: String, default: undefined, required: false })
  address?: string;
}
