
























import { Action, Getter, Mutation } from "vuex-class";
import EthereumHelper from "@/helpers/ethereum.helper";
import TradeListHelper from "@/helpers/tradeList.helper";
import { ITrade, ITradePagination } from "@/store/offers";
import { ITradeListFilterSubmit } from "./TradeListFilter.vue";
import TradeList, { ITradeListOptions } from "./TradeList.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    TradeList,
  },
})
export default class TradesSuggested extends Vue {
  @Prop({ type: Object, required: true, default: {} })
  listFilter!: ITradeListFilterSubmit;

  itemsTotal = 0;
  blockCurrentNumber: number | null = null;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  @Getter("isLoading", { namespace: "trades" })
  isLoading!: boolean;
  @Getter("trades", { namespace: "trades" })
  trades: Array<ITrade> | undefined;
  @Mutation("setTrades", { namespace: "trades" }) setTrades!: (
    payload: Array<ITrade>
  ) => any;
  @Action("tradesGet", { namespace: "trades" })
  tradesGet!: (options?: ITradeListOptions) => any;

  get envPaginationPerPage(): number {
    return parseInt(process.env.VUE_APP_PAGINATION_PER_PAGE ?? "25");
  }

  get tradesFiltered() {
    return TradeListHelper.filterTradeList(
      this.trades ?? [],
      this.listFilter,
      this.blockCurrentNumber ?? undefined,
      true
    );
  }

  mounted() {
    this.setBlockCurrentNumber();
    this.setTrades([]);
    this.fetch({
      page: 1,
      itemsPerPage: this.envPaginationPerPage,
    });
  }

  async setBlockCurrentNumber() {
    this.blockCurrentNumber =
      (await this.ethereumHelper.provider?.getBlockNumber()) ?? null;
  }

  async fetch(options?: ITradeListOptions) {
    const res: ITradePagination = await this.tradesGet(options);
    this.itemsTotal = res.itemsTotal?.toNumber() ?? 0;
  }
}
