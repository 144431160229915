









































import { ITrade } from "@/store/offers";
import { BigNumber } from "bignumber.js";
import TokenHeader from "../Token/TokenHeader.vue";
import tokensHelper, { IToken } from "@/store/tokens";
import TradeItemHelper from "@/helpers/tradeItem.helper";
import ERC20AmountHelpers from "@/helpers/erc20Amounts.helper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { EthereumHelper as EHClass } from "@/helpers/ethereum.helper";
@Component({
  name: "TradeItemTokensRight",
  components: {
    TokenHeader,
  },
})
export default class TradeItemTokensRight extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;
  @Prop({ required: false, default: undefined })
  blockCurrentNumber!: number | undefined;

  mounted() {
    this.setInitialSelectedTokenToIndex();
  }

  selectedIndex: number | null = null;

  @Watch("selectedIndex", {
    deep: false,
    immediate: true,
  })
  selectedIndexChanged() {
    this.$emit("updateSelectedIndex", this.selectedIndex);
  }

  /**
   * Null disables the selector
   */
  get selectedIndexValue(): number | null {
    if (!this.tradeItem.tokensRight) return null;
    if (this.tradeItem.tokensRight.length < 2) return null;
    return this.selectedIndex;
  }
  get isTokenSelectorDisabled() {
    return !this.isActive || this.selectedIndexValue === null || this.isExpired;
  }

  get isExpired(): boolean {
    return TradeItemHelper.isExpired(this.tradeItem, this.blockCurrentNumber);
  }

  /**
   * Set Selected TokenTo
   *
   * a) Show Selected TokenTo if Trade is Accepted.
   * b) Show first TokenTo selected if Trade is not accepted
   * (as a pre-selection).
   */
  setInitialSelectedTokenToIndex() {
    if (!this.tradeItem.tokensRight) {
      /**
       * No tokens on the right side,
       * abort.
       */
      return;
    }

    /**
     * Trade is Accepted
     * Get and Show Selected TokenTo
     */
    if (this.isAccepted) {
      if (
        this.savedRightTokenSelectIndex !== null &&
        this.savedRightTokenSelectIndex !== -1
      ) {
        this.selectedIndex = this.savedRightTokenSelectIndex;
      }
    } else if (!this.isExpired) {
      /**
       * Trade isn't Accepted.
       *
       * Set first TokenTo as selected.
       * This is a pre-selection,
       * to help the user speed-up the process.
       */
      this.selectedIndex = 0;
      return;
    }
  }
  get savedRightTokenSelectIndex(): number | null {
    if (!this.isAccepted) return null;
    return EHClass.getIndexOfTokenRightInTrade(
      this.tradeItem,
      this.tradeItem.tokenRightSelected!
    );
  }

  get isAccepted() {
    return TradeItemHelper.isAccepted(this.tradeItem);
  }

  /**
   * Flags
   */

  get isActive() {
    return !!this.tradeItem.isActive;
  }

  get isCompleted() {
    return !!this.tradeItem.isCompleted;
  }

  getTokenByAddress(val: string): IToken | undefined {
    return tokensHelper.getTokenByAddress(val);
  }

  getTokenAmount(token: IToken, amount: BigNumber): BigNumber {
    return ERC20AmountHelpers.transformAmountToTokensBasedOnDecimals(
      new BigNumber(amount.toString()),
      token.decimals
    );
  }
}
