import { IToken } from "@/store/tokens";
import { BigNumber } from "bignumber.js";
import chainHelper from "./chain.helper";
import ERC20Helper from "./erc20.helper";
import { ADDRESS_NULL } from "./constants";
import { CHAIN_ID_DEFAULT } from "@/store/chains";
import { ethers, ContractTransaction } from "ethers";
import { InfuraProvider } from "@ethersproject/providers";
import { ITrade, ITradeDetails, ITradePagination } from "@/store/offers";
import userTokensHelper from "@/store/userTokens/userTokens.helper";

/**
 * Ethereum Helper
 */
export class EthereumHelper {
  public provider?: ethers.providers.Web3Provider | InfuraProvider;
  public signer?: ethers.providers.JsonRpcSigner;
  /**
   * Used to detect user-logged-in status accross the app,
   * inside the components.
   *
   * Set it by getting address via signer.getAddress().
   */
  public signerAddress?: string;
  public contract?: ethers.Contract;

  constructor() {
    if (window.ethereum) {
      // Provider, Signer
      this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      this.signer = this.provider?.getSigner();

      this.observeAccountChange();
      this.syncSignerAddress();
    } else {
      this.provider = new InfuraProvider(
        CHAIN_ID_DEFAULT,
        process.env.VUE_APP_INFURA_API_KEY
      );
    }
  }

  async getNetworkDeploymentAndInitContract(
    provider?: ethers.providers.Web3Provider | InfuraProvider,
    signer?: ethers.providers.JsonRpcSigner
  ) {
    // Fetch Network
    const network = await chainHelper.getCurrentNetwork();

    // Get network chainId
    const chainId = network?.chainId;
    if (!chainId) throw Error("No chain");

    const deployment = chainHelper.getContractDeploymentPerChain(chainId);
    if (!deployment) throw Error("No address or abi for deployment");
    this.contractInit(deployment.address, deployment.abi, provider, signer);
  }

  async contractInit(
    contractAddress: string,
    contractAbi: Array<string>,
    provider?: ethers.providers.Web3Provider | InfuraProvider,
    signer?: ethers.providers.JsonRpcSigner
  ) {
    /**
     * If no account is connected,
     * signer.getAddress() will throw an Error (unknown account #0).
     *
     * Catch it and set signer to undefined,
     * use provider instead of it,
     * when constructing the Contract.
     */
    try {
      if (signer && !(await signer.getAddress())) signer = undefined;
    } catch (error) {
      signer = undefined;
    }

    this.contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer ?? provider
    );
  }

  /**
   * Get Index of Token in tokensRight in Trade
   *
   * @param {ITrade} trade
   * @param {string} tokenAddress
   *
   * @returns {number} -1 if not found
   */
  static getIndexOfTokenRightInTrade(
    trade: ITrade,
    tokenAddress: string
  ): number {
    if (!trade.tokensRight) throw new Error("trade.tokensRight undefined");
    return trade.tokensRight.findIndex((item) => item === tokenAddress);
  }

  /**
   * tradeAccept
   *
   * @returns {Promise<ContractTransaction>} Transaction
   */
  async tradeAccept(
    trade: ITrade,
    tokenRightSelected: IToken
  ): Promise<ContractTransaction> {
    /**
     * Validation
     */
    if (!this.contract) throw new Error("Contract undefined");
    if (!trade.tokensRight) throw new Error("Trade.tokensRight undefined");
    if (!trade.tokensRightAmounts)
      throw new Error("Trade.tokensRightAmounts undefined");
    if (!tokenRightSelected.address)
      throw new Error("tokenRightSelected.address undefined");

    // Find tokenRightSelected index
    const tokenRightSelectedIndex = EthereumHelper.getIndexOfTokenRightInTrade(
      trade,
      tokenRightSelected.address
    );
    if (tokenRightSelectedIndex === -1) {
      throw new Error("tokenRightSelected not found in the Trade");
    }
    // Find TokenRight by tokenRightSelectedIndex
    const foundTokenRightAmount =
      trade.tokensRightAmounts[tokenRightSelectedIndex];

    /**
     * Attach Wei if tokenRightSelected is Ether
     */
    let additional = {};
    if (tokenRightSelected.address === ADDRESS_NULL) {
      // Add Wei to the Call
      additional = {
        ...additional,
        value: foundTokenRightAmount,
      };
    } else {
      // Approve the Transfer to the Contract
      if (!tokenRightSelected.address)
        throw Error("No contract address defined");
      const erc20Helper = new ERC20Helper(tokenRightSelected.address);

      try {
        await erc20Helper.approve(
          new BigNumber(foundTokenRightAmount.toString())
        );
      } catch (error) {
        console.error("Approve Error", error);
      }
    }

    /**
     * Send Transaction
     */
    const tx = await this.contract.tradeAccept(
      trade.tradeID,
      tokenRightSelected.address,
      additional
    );
    return tx;
  }

  /**
   * tradeCancel
   *
   * @returns {Promise<ContractTransaction>}
   */
  async tradeCancel(tradeId: BigNumber): Promise<ContractTransaction> {
    if (!this.contract) throw Error("Contract not initialized");

    return await this.contract.tradeCancel(tradeId);
  }

  /**
   * User Trades Get
   *
   * @returns {Promise<ITradePagination>} User Trades
   */
  async userTradesGet(
    user: string,
    page: string = ADDRESS_NULL,
    perPage: string = ADDRESS_NULL
  ): Promise<ITradePagination> {
    if (!this.contract) {
      throw new Error("Contract not available");
    }
    const items = await this.contract.userTradesGet(user, page, perPage);
    return items;
  }

  /**
   * Trades Get
   *
   * @returns {Promise<ITradePagination>} Trades
   */
  async tradesGet(
    page: string = ADDRESS_NULL,
    perPage: string = ADDRESS_NULL
  ): Promise<ITradePagination> {
    if (!this.contract) {
      throw new Error("Contract not available");
    }
    const items = await this.contract.tradesGet(page, perPage);
    return items;
  }

  /**
   * getTradeById
   *
   * @param {BigNumber} tradeId
   *
   * @returns {Promise<ITrade>} Trade
   */
  async getTradeById(tradeId: string): Promise<ITrade> {
    if (!this.contract) {
      throw new Error("Contract not available");
    }
    const item = await this.contract.tradeGetById(tradeId);
    return item;
  }

  /**
   * tradeGetDetailsById
   *
   * @param {BigNumber} tradeId
   *
   * @returns {Promise<ITrade>}
   */
  async tradeGetDetailsById(tradeId: string): Promise<ITradeDetails> {
    if (!this.contract) {
      throw new Error("Contract not available");
    }
    const item = await this.contract.tradeGetDetailsById(tradeId);
    return item;
  }

  /**
   * Opens a login confirmation window
   *
   * User is presented with account and permission select screen.
   *
   * @returns {Promise<void>}
   */
  async openLoginWindow(): Promise<void> {
    if (!this.provider) {
      throw new Error("Provider not available.");
    }
    await this.provider.send("eth_requestAccounts", []);
  }

  /**
   * If no account is connected,
   * signer.getAddress() will throw an Error (unknown account #0).
   *
   * Catch it and set signer to undefined,
   * use provider instead of it,
   * when constructing the Contract.
   */
  async syncSignerAddress() {
    try {
      this.signerAddress = await this.signer?.getAddress();
    } catch (error) {
      // Address not available, reset to undefined
      this.signerAddress = undefined;
    }
    return this.signerAddress;
  }

  /**
   * Logout the user
   * i.e. Clear Signer data
   */
  clearSigner() {
    this.signer = undefined;
    this.signerAddress = undefined;
    this.getNetworkDeploymentAndInitContract(this.provider, this.signer);
  }

  async getBalance(address?: string): Promise<BigNumber | undefined> {
    if (!this.provider) return undefined;

    /**
     * No address passed.
     *
     * Return Balance of the logged in user
     */
    if (!address && this.signer) {
      return new BigNumber((await this.signer.getBalance()).toString());
    }

    /**
     * Address
     */
    if (address) {
      return new BigNumber(
        (await this.provider.getBalance(address)).toString()
      );
    }
    return undefined;
  }

  /**
   * Login and get account address
   *
   * @returns {Promise<string|undefined>}
   */
  async loginAndGetAccountAddress(): Promise<string | undefined> {
    try {
      await this.openLoginWindow();
    } catch (error) {
      console.warn("Login.error", error);
      return undefined;
    }
    this.signer = this.provider?.getSigner();
    await this.syncSignerAddress();
    this.getNetworkDeploymentAndInitContract(this.provider, this.signer);
    return this.signerAddress;
  }

  async observe() {
    if (!this.provider) return;
    if (!this.contract) return;
    this.contract.on("TradeCreated", this.tradeCreatedListener);
  }

  async observeAccountChange() {
    if (!this.provider) return;
    window.ethereum.on("accountsChanged", (accounts: any) => {
      this.handleAccountsChanged(accounts);
    });
  }

  /**
   * Handle accounts changed.
   */
  async handleAccountsChanged(accounts: any) {
    // Reset user tokens
    await userTokensHelper.storeTokensSet([]);
    /**
     * User can disconnect all accounts from the site,
     * thus, we're fetching Signer every time,
     * and re-initing Contract
     * (so if we don't have the Signer anymore, we use the Contract in read-only mode).
     */
    this.signer = this.provider?.getSigner();
    /**
     * Set Address to the current,default,connected one.
     * Or to undefined if nothing is available.
     */
    this.syncSignerAddress();
    this.getNetworkDeploymentAndInitContract(this.provider, this.signer);
    console.debug("--Accounts-Changed", accounts);
  }
  async tradeCreatedListener(tradeID: BigNumber) {
    console.log("---TradeCreated--Listener", tradeID);
  }
}

export default new EthereumHelper();
