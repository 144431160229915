/**
 * Chain IDs
 */
// Chains
export const CHAIN_ID_MAINNET = 1;
export const CHAIN_ID_GOERLI = 5;
export const CHAIN_ID_POLYGON = 137;
export const CHAIN_ID_POLYGON_MUMBAI = 80001;
export const CHAIN_ID_LOCAL = 31337;
// Default chain id
export const CHAIN_ID_DEFAULT =
  (process.env.VUE_APP_CHAIN_ID_DEFAULT
    ? parseFloat(process.env.VUE_APP_CHAIN_ID_DEFAULT)
    : undefined) ?? CHAIN_ID_GOERLI;

/**
 * Chain Interface
 */
export interface IChainNativeCurrency {
  name: string;
  symbol: string;
  decimals: number;
}
export interface IChain {
  id: number;
  name: string;
  rpc_url: string;
  avatar_url: string;
  nativeCurrency: IChainNativeCurrency;
  blockCreationTimeSeconds: number;
  explorerUrlTransactionPrefix?: string;
}

/**
 * List
 */
export const chains: IChain[] = [
  {
    id: CHAIN_ID_MAINNET,
    name: "Ethereum Mainnet",
    rpc_url: "https://mainnet.infura.io/v3/",
    avatar_url: "/img/tokens/fantom.png",
    blockCreationTimeSeconds: 15,
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
  },
  {
    id: CHAIN_ID_GOERLI,
    name: "Goerli Testnet",
    rpc_url: "https://goerli.infura.io/v3/",
    avatar_url: "/img/tokens/fantom.png",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockCreationTimeSeconds: 15,
    explorerUrlTransactionPrefix: "https://goerli.etherscan.io/tx/",
  },
  // {
  //   id: CHAIN_ID_POLYGON_MUMBAI,
  //   name: "Polygon Testnet",
  //   rpc_url: "https://rpc-mumbai.maticvigil.com/",
  //   avatar_url: "/img/tokens/polygon.png",
  //   nativeCurrency: {
  //     name: "tMATIC",
  //     symbol: "tMATIC",
  //     decimals: 18,
  //   },
  //   blockCreationTimeSeconds: 2,
  //   explorerUrlTransactionPrefix: "https://mumbai.polygonscan.com/tx/",
  // },
  // {
  //   id: CHAIN_ID_POLYGON,
  //   name: "Polygon Mainnet",
  //   rpc_url: "https://polygon-rpc.com",
  //   avatar_url: "/img/tokens/usd-coin.png",
  //   blockCreationTimeSeconds: 15,
  //   nativeCurrency: {
  //     name: "tMATIC",
  //     symbol: "tMATIC",
  //     decimals: 18,
  //   },
  // },
  // {
  //   id: 10,
  //   name: "Optimism",
  //   rpc_url: "https://mainnet.optimism.io",
  //   avatar_url: "/img/tokens/usd-coin.png",
  // },
  // {
  //   id: 42161,
  //   name: "Arbitrum One",
  //   rpc_url: "https://arb1.arbitrum.io/rpc",
  //   avatar_url: "/img/tokens/usd-coin.png",
  // },
  // {
  //   id: CHAIN_ID_LOCAL,
  //   name: "Local Testnet",
  //   rpc_url: "https://localhost:8545/",
  //   avatar_url: "/img/tokens/usd-coin.png",
  //   nativeCurrency: {
  //     name: "Ether",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   blockCreationTimeSeconds: 1,
  // },
];

export default chains;
