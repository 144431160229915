import { GetterTree } from "vuex";
import { RootState } from "../types";
import { UserTokensState } from "./types";
import { IToken, ITokenAndAmount } from "../tokens";

export const getters: GetterTree<UserTokensState, RootState> = {
  isLoading(state): boolean | undefined {
    const { isLoading } = state;
    return isLoading;
  },
  tokens(state): Array<ITokenAndAmount> | undefined {
    const { tokens } = state;
    return tokens;
  },
  getTokenByAddress:
    (state, getters) =>
    (address: string): ITokenAndAmount | undefined => {
      const tokens: Array<ITokenAndAmount> = getters["tokens"]();
      return tokens.find((item: ITokenAndAmount) => {
        return item.token?.address === address;
      });
    },
};
