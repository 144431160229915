/**
 * Main
 * Store, Router
 */
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

/**
 * ServiceWorker
 */
import "./registerServiceWorker";

/**
 * Plugins
 */

import vuetify from "./plugins/vuetify";

import VueTimeago from "vue-timeago";
Vue.use(VueTimeago, {});

import Notifications from "vue-notification";
import velocity from "velocity-animate";
Vue.use(Notifications, { velocity });

import contenteditable from "vue-contenteditable";
Vue.use(contenteditable);

import VueMoment from "vue-moment";
import moment from "./momentjs.config";
Vue.use(VueMoment, { moment });

Vue.filter("capitalize", function (value: any) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.config.productionTip = false;

/**
 * Bootstrap
 */
new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
