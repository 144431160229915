
















import { ITrade } from "@/store/offers";
import UserHelper from "@/helpers/user.helper";
import UserAvatar from "../User/UserAvatar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TradeItemCreator",
  components: {
    UserAvatar,
  },
})
export default class TradeItemCreator extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;

  get addressShort(): string | null {
    return UserHelper.shortifyAddress(this.tradeItem.userCreator);
  }
}
