import ethereumHelper from "./ethereum.helper";
import { BigNumber, Event, EventFilter } from "ethers";
import { TransactionResponse } from "@ethersproject/abstract-provider";

/**
 * EventsHelper
 */
export class EventsHelper {
  /**
   * Event Transactions
   */
  async getTradeCreatedEventTx(
    tradeId: BigNumber
  ): Promise<TransactionResponse | null> {
    const event = await this.getTradeCreatedEvent(tradeId);
    if (!event) return null;
    return await event.getTransaction();
  }
  async getTradeAcceptedEventTx(
    tradeId: BigNumber
  ): Promise<TransactionResponse | null> {
    const event = await this.getTradeAcceptedEvent(tradeId);
    if (!event) return null;
    return await event.getTransaction();
  }
  async getTradeCancelledEventTx(
    tradeId: BigNumber
  ): Promise<TransactionResponse | null> {
    const event = await this.getTradeCancelledEvent(tradeId);
    if (!event) return null;
    return await event.getTransaction();
  }

  /**
   * Events
   */
  async getTradeCreatedEvent(tradeId: BigNumber): Promise<Event | null> {
    const filter = ethereumHelper.contract!.filters.TradeCreated(tradeId);
    return this.eventQueryFilter(filter);
  }
  async getTradeAcceptedEvent(tradeId: BigNumber): Promise<Event | null> {
    const filter = ethereumHelper.contract!.filters.TradeAccepted(tradeId);
    return this.eventQueryFilter(filter);
  }
  async getTradeCancelledEvent(tradeId: BigNumber): Promise<Event | null> {
    const filter = ethereumHelper.contract!.filters.TradeCancelled(tradeId);
    return this.eventQueryFilter(filter);
  }

  /**
   * Helpers
   */
  private async eventQueryFilter(filter: EventFilter) {
    const items = await ethereumHelper.contract!.queryFilter(filter);
    return items && items.length ? items[0] : null;
  }
}

export default new EventsHelper();
