import store from "@/store";
import { ethers, utils } from "ethers";
import chains, { CHAIN_ID_DEFAULT, IChain } from "@/store/chains";
import { ChainSwitcherStatus } from "@/store/chains/types";

/**
 * Import deployments JSON file from `@pazar/hardhat` npm package.
 * This JSON file holds deployment details.
 */
import deployments from "@pazar/hardhat/deployments/deployments.json";
import { InfuraProvider } from "@ethersproject/providers";
import ethereumHelper from "./ethereum.helper";

/**
 * TODO: Move to .env
 * Used to find Contract by name in deployments JSON file.
 */
export const contractName = "TrustlessOTC";

/**
 * Contract Deployment Interface
 */
export interface IContractDeployment {
  abi: Array<any>;
  address: string;
}

/**
 * Chain Helper
 */
export class ChainHelper {
  public provider?: ethers.providers.Web3Provider | InfuraProvider;
  public network?: ethers.providers.Network;

  constructor() {
    if (window.ethereum) {
      this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      this.observeNetworkChange();
    } else {
      this.provider = new InfuraProvider(
        CHAIN_ID_DEFAULT,
        process.env.VUE_APP_INFURA_API_KEY
      );
    }
  }

  /**
   * Get Chain from our list of supported Chains
   * by id
   *
   * @returns {IChain | undefined}
   */
  getChain(id: number): IChain | undefined {
    return chains.find((chain: IChain) => {
      return chain.id === id;
    });
  }

  /**
   * Is Chain Supported
   *
   * We're checking our list of supported chains in the app.
   *
   * @returns {boolean}
   */
  isChainSupported(id: number): boolean {
    return this.getChain(id) ? true : false;
  }

  /**
   * Get Contract Deployment per Chain
   *
   * @param {number} chainId
   *
   * @returns {IContractDeployment | undefined}
   */
  getContractDeploymentPerChain(
    chainId: number
  ): IContractDeployment | undefined {
    /**
     * Find deployment
     */
    const entry = Object.values(deployments).find((value) => {
      if (value[0].chainId === chainId.toString()) {
        return true;
      }
      return false;
    });
    const deployment = entry ? entry[0] : null;

    /**
     * Find details
     */
    const contract = deployment?.contracts[contractName];
    const abi = contract?.abi;
    const address = contract?.address;

    /**
     * Return
     */
    return abi && address ? { abi, address } : undefined;
  }

  /**
   * Get User's Current Network
   */
  async getCurrentNetwork(): Promise<ethers.providers.Network | undefined> {
    if (!this.provider) throw Error("Provider not available");
    this.network = await this.provider.getNetwork();
    return this.network;
  }

  /**
   * Switch Network
   *
   * Returns status and updates store
   *
   * Store is updated to allow dynamic access to status changes,
   * throughout the app (use the Getter to access it).
   * It is used in App.vue to show current state of Chain Switching to a supported network.
   *
   * Return value serves as a immediate way for error handling.
   */
  async switchNetwork(chain: IChain): Promise<ChainSwitcherStatus> {
    if (!this.provider) throw Error("Provider not available");
    if (this.provider instanceof InfuraProvider) {
      this.provider = new InfuraProvider(
        chain.id,
        process.env.VUE_APP_INFURA_API_KEY
      );
      // Switch Ethereum Helper provider to a different network
      ethereumHelper.provider = new InfuraProvider(
        chain.id,
        process.env.VUE_APP_INFURA_API_KEY
      );
      await ethereumHelper.getNetworkDeploymentAndInitContract(
        ethereumHelper.provider
      );
      return Promise.resolve(ChainSwitcherStatus.SUCCESS);
    }
    const id = utils.hexValue(chain.id);
    await store.dispatch(
      "chains/setChainSwitcherStatus",
      ChainSwitcherStatus.REQUIRES_USER_ACTION
    );
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: id }],
      });
    } catch (switchError: any) {
      console.error("Chain not switched.", switchError);
      if (switchError.code === 4001) {
        // User rejected the request
        await store.dispatch(
          "chains/setChainSwitcherStatus",
          ChainSwitcherStatus.USER_REJECTED
        );
        return ChainSwitcherStatus.USER_REJECTED;
      } else if (switchError.code === -32002) {
        /**
         * Request to Switch the Network is already pending in the Wallet
         * (not accepted, not rejected).
         */
        await store.dispatch(
          "chains/setChainSwitcherStatus",
          ChainSwitcherStatus.PENDING_REQUIRES_USER_ACTION
        );
        return ChainSwitcherStatus.PENDING_REQUIRES_USER_ACTION;
      } else if (switchError.code === 4902) {
        try {
          await store.dispatch(
            "chains/setChainSwitcherStatus",
            ChainSwitcherStatus.ALLOW_TO_ADD_NETWORK
          );
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: id,
                chainName: chain.name,
                rpcUrls: [chain.rpc_url],
                nativeCurrency: chain.nativeCurrency,
              },
            ],
          });
          await store.dispatch(
            "chains/setChainSwitcherStatus",
            ChainSwitcherStatus.REQUIRES_USER_ACTION
          );
        } catch (addError: any) {
          console.error("Chain not added.", addError);
          await store.dispatch(
            "chains/setChainSwitcherStatus",
            ChainSwitcherStatus.FAILED_CANNOT_ADD_CHAIN
          );
          return ChainSwitcherStatus.FAILED_CANNOT_ADD_CHAIN;
        }
      }
    }
    await store.dispatch(
      "chains/setChainSwitcherStatus",
      ChainSwitcherStatus.SUCCESS
    );
    return ChainSwitcherStatus.SUCCESS;
  }

  /**
   * Observe Network Change
   *
   * Reload the site on network change.
   * We will re-init contract and re-setup contracts,
   * signers etc. on network change, to reflect current state in the Wallet.
   */
  observeNetworkChange() {
    if (!this.provider) throw Error("Provider not available");
    this.provider.on("network", (newNetwork, oldNetwork) => {
      console.log("Network change:", newNetwork, oldNetwork);
      if (oldNetwork) {
        // Network has changed
        window.location.reload();
      } else {
        // Initial trigger
      }
    });
  }
}

export default new ChainHelper();
