import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { RootState } from "../types";
import { mutations } from "./mutations";
import { TransactionsState } from "./types";

export const state: TransactionsState = {
  transactions: [],
};

const namespaced = true;

export const transactions: Module<TransactionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
