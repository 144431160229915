import { GetterTree } from "vuex";
import { ITrade, ITradeDetails } from "../offers";
import BigNumber from "bignumber.js";
import { RootState } from "../types";
import { TradesState } from "./types";

export const getters: GetterTree<TradesState, RootState> = {
  isLoading(state): boolean | undefined {
    const { isLoading } = state;
    return isLoading;
  },
  trades(state): Array<ITrade> | undefined {
    const { trades } = state;
    return trades;
  },
  getTradeDetailById:
    (state) =>
    (id: string): ITradeDetails | undefined => {
      const { tradeDetails } = state;
      return tradeDetails?.find((item) => {
        const tradeId = item.trade!.tradeID!;
        return tradeId.toString() === id.toString();
      });
    },
  /**
   * Flags
   */
  tradeIsAcceptPending:
    (state) =>
    (id: BigNumber): boolean => {
      const val = state.tradesFlags[id.toString()] ?? {};
      return val.isAcceptPending || false;
    },
  tradeIsCancelPending:
    (state) =>
    (id: BigNumber): boolean => {
      const val = state.tradesFlags[id.toString()] ?? {};
      return val.isCancelPending ?? false;
    },
};
