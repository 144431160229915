import * as moment from "moment";

moment.relativeTimeThreshold("ss", 1);
moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24);
moment.relativeTimeThreshold("d", 7);
moment.relativeTimeThreshold("w", 4);
moment.relativeTimeThreshold("M", 12);
const retainValue = function (value: any) {
  return value.toPrecision(3);
};
moment.relativeTimeRounding(retainValue);
moment.updateLocale("en", {
  relativeTime: {
    // ss: function (number, withoutSuffix, key, isFuture) {
    //   return Math.floor(number) + " seconds";
    // },
    // mm: function (number, withoutSuffix, key, isFuture) {
    //   return Math.floor(number) + " minutes";
    // },
    // hh: function (number, withoutSuffix, key, isFuture) {
    //   return Math.floor(number) + " hours";
    // },
  },
});
export default moment;
