


























import NotificationHelper, {
  NotificationType,
} from "../../helpers/notification.helper";
import UserHelper from "@/helpers/user.helper";
import UserAvatar from "../User/UserAvatar.vue";
import UserConnect from "../User/UserConnect.vue";
import EthereumHelper from "@/helpers/ethereum.helper";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {
    UserAvatar,
    UserConnect,
  },
})
export default class NavigationUser extends Vue {
  @Prop({ type: String, required: false, default: "" })
  name!: string;

  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  get address() {
    return this.ethereumHelper.signerAddress;
  }
  get addressShort(): string | null {
    return UserHelper.shortifyAddress(this.address);
  }

  logout() {
    this.ethereumHelper.clearSigner();
    NotificationHelper.publish(
      "User Un-Authenticated",
      undefined,
      NotificationType.SUCCESS
    );
  }
}
