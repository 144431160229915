/**
 * User Helper
 */
export class UserHelper {
  static shortifyAddress(address?: string): string | null {
    if (!address) return null;
    const prefix = address.slice(0, 5);
    const suffix = address.slice(address.length - 5);
    return (prefix + "..." + suffix).toUpperCase();
  }
}

export default UserHelper;
