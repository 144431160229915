

















import { Action, Getter, Mutation } from "vuex-class";
import EthereumHelper from "../helpers/ethereum.helper";
import UserHeader from "@/components/User/UserHeader.vue";
import { ITrade, ITradePagination } from "@/store/offers";
import UserTokens from "../components/User/UserTokens.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import TradeList, { ITradeListOptions } from "@/components/Trade/TradeList.vue";
@Component({
  components: {
    TradeList,
    UserHeader,
    UserTokens,
  },
})
export default class UserView extends Vue {
  @Getter("isLoading", { namespace: "trades" })
  isLoading!: boolean;
  @Getter("trades", { namespace: "trades" })
  trades: Array<ITrade> | undefined;
  @Mutation("setTrades", { namespace: "trades" })
  setTrades!: (payload: Array<ITrade>) => any;
  @Action("userTradesGet", { namespace: "trades" })
  userTradesGet!: (payload: { id: string; options?: ITradeListOptions }) => any;

  id: string | null = null;
  itemsTotal = 0;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;
  get envPaginationPerPage(): number {
    return parseInt(process.env.VUE_APP_PAGINATION_PER_PAGE ?? "25");
  }

  get contract(): any | null {
    return this.ethereumHelper?.contract ?? null;
  }

  @Watch("$route", {
    deep: false,
    immediate: true,
  })
  routeChanged(valueNew: any, valueOld: any) {
    this.id = this.$route.params.id;
    this.setTrades([]);
    this.fetch({
      page: 1,
      itemsPerPage: this.envPaginationPerPage,
    });
  }

  /**
   * If using Infura as a provider,
   * and Network was changed with NavigationChain component
   * - refetch data on a new network.
   */
  @Watch("contract", {
    deep: false,
    immediate: false,
  })
  contractUpdated() {
    if (!this.isLoading) {
      this.setTrades([]);
      this.fetch({
        page: 1,
        itemsPerPage: this.envPaginationPerPage,
      });
    }
  }

  async fetch(options?: ITradeListOptions) {
    const res: ITradePagination = await this.userTradesGet({
      id: this.id!,
      options,
    });
    this.itemsTotal = res.itemsTotal?.toNumber() ?? 0;
  }
}
