import { ITokenList } from "./tokenLists";

export const tokenLists: Array<ITokenList> = [
  {
    isActive: true,
    name: "Your personal Token list",
    logoURI: "/img/theme/logo.svg",
    tokens: [],
    isUserList: true,
  },
  {
    isActive: true,
    name: "Pazar",
    logoURI: "/img/theme/logo.svg",
    tokens: [
      {
        chainId: 31337,
        symbol: "ETH",
        name: "Ether",
        decimals: 18,
        address: "0x0000000000000000000000000000000000000000",
        logoURI: "/img/tokens/eth.png",
      },
      {
        chainId: 31337,
        symbol: "ERC201",
        name: "ERC20 1",
        decimals: 18,
        address: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
        logoURI: "/img/tokens/erc201.png",
      },
      {
        chainId: 31337,
        symbol: "ERC202",
        name: "ERC20 2",
        decimals: 18,
        address: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
        logoURI: "/img/tokens/erc202.png",
      },

      {
        chainId: 5,
        symbol: "ETH",
        name: "Ether",
        decimals: 18,
        address: "0x0000000000000000000000000000000000000000",
        logoURI: "/img/tokens/eth.png",
      },
      {
        chainId: 5,
        symbol: "ERC201",
        name: "ERC20 1",
        decimals: 18,
        address: "0xE9C6Bd44567aC299A24E0755ae525e5009645b0e",
        logoURI: "/img/tokens/erc201.png",
      },
      {
        chainId: 5,
        symbol: "ERC202",
        name: "ERC20 2",
        decimals: 18,
        address: "0x2C788d2047adae7fd70fD8fC5A4d31f4E25a2a98",
        logoURI: "/img/tokens/erc202.png",
      },

      {
        chainId: 80001,
        symbol: "tMATIC",
        name: "tMATIC",
        decimals: 18,
        address: "0x0000000000000000000000000000000000000000",
        logoURI: "/img/tokens/polygon.png",
      },
      {
        chainId: 80001,
        symbol: "ERC201",
        name: "ERC20 1",
        decimals: 18,
        address: "0x2aAfD7037547Ec1C47E1259aD2afB8350bb93e30",
        logoURI: "/img/tokens/erc201.png",
      },
      {
        chainId: 80001,
        symbol: "ERC202",
        name: "ERC20 2",
        decimals: 18,
        address: "0xA139aeb98541E33B6F59BC61AE06EAf6c765660A",
        logoURI: "/img/tokens/erc202.png",
      },
    ],
  },
  {
    isActive: true,
    name: "Uniswap Labs Default",
    jsonURI: "https://tokens.uniswap.org/",
    tokens: [],
  },
  {
    isActive: true,
    name: "CoinGecko",
    logoURI:
      "https://www.coingecko.com/assets/thumbnail-007177f3eca19695592f0b8b0eabbdae282b54154e1be912285c9034ea6cbaf2.png",
    jsonURI: "https://tokens.coingecko.com/uniswap/all.json",
    tokens: [],
  },
  {
    isActive: true,
    name: "Compound",
    logoURI:
      "https://raw.githubusercontent.com/compound-finance/token-list/master/assets/compound-interface.svg",
    jsonURI:
      "https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json",
    tokens: [],
  },
];

export default tokenLists;
