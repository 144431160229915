import { IToken } from "@/store/tokens";

/**
 * Wallet Assets Helper
 */
export class WalletAssetsHelper {
  static addTokenToWallet(token: IToken) {
    if (!window.ethereum)
      throw "window.ethereum undefined: Cannot add Wallet asset";
    window.ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
            image: token.logoURI,
          },
        },
      })
      .then((success: any) => {
        if (success) {
          console.log(token.name + " successfully added to wallet!");
        } else {
          throw new Error("Something went wrong.");
        }
      })
      .catch(console.error);
  }
}

export default WalletAssetsHelper;
