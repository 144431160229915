import { BigNumber } from "bignumber.js";

/**
 * ERC20 Basic Amount Helpers
 */
export class ERC20AmountHelpers {
  /**
   * Calculate Amount Based On Decimals
   *
   * https://ethereum.stackexchange.com/questions/19673/decimals-on-erc20-tokens
   *
   * @param {number} tokens
   * @param {number} decimals
   *
   * @returns {BigNumber}
   */
  static calculateAmountBasedOnDecimals(
    tokens: number,
    decimals = 18
  ): BigNumber {
    const val = new BigNumber(tokens);
    return val.shiftedBy(decimals);
  }

  /**
   * Transform amount to Tokens Based on Decimals
   *
   * @param {BigNumber} amount
   * @param {number}    decimals
   *
   * @returns {BigNumber}
   */
  static transformAmountToTokensBasedOnDecimals(
    amount: BigNumber,
    decimals = 18
  ): BigNumber {
    const val = new BigNumber(amount);
    return val.shiftedBy(-decimals);
  }
}

export default ERC20AmountHelpers;
