

















































import { IToken } from "@/store/tokens";
import { TradeTokenSide } from "@/store/trades";
import { ITokenAndAmount } from "@/store/tokens";
import chainHelper from "../../helpers/chain.helper";
import TradeCreateOptional from "./TradeCreateOptional.vue";
import TokenSelectDialog from "../Token/TokenSelectDialog.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TokenHeaderHorizontal from "../Token/TokenHeaderHorizontal.vue";
@Component({
  components: {
    TokenSelectDialog,
    TradeCreateOptional,
    TokenHeaderHorizontal,
  },
})
export default class TradeCreateRequiredSide extends Vue {
  @Prop({ type: Array, required: true, default: undefined })
  tokens!: Array<IToken>;
  @Prop({ type: Array, required: false, default: undefined })
  selected!: Array<ITokenAndAmount>;
  @Prop({ type: Boolean, required: false, default: true })
  isEditable!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isMultiToken!: boolean;
  @Prop({ type: String, required: false, default: null })
  side!: TradeTokenSide;
  @Prop({ type: Object, required: false, default: undefined })
  tokenInitial!: IToken | undefined;

  isTokenDialogVisible = false;
  tokenReplace: IToken | null = null;
  selectedLocal: Array<ITokenAndAmount> = [];
  get heading() {
    if (!this.side) return "Side";
    return this.side === TradeTokenSide.LEFT ? "You pay" : "You receive";
  }
  get isRemoveVisible() {
    return this.selectedLocal && this.selectedLocal.length > 1;
  }

  mounted() {
    this.setSelectedInitial();
  }

  @Watch("selected", {
    deep: true,
    immediate: false,
  })
  selectedChanged() {
    this.setSelectedInitial();
  }
  @Watch("tokenInitial", {
    deep: true,
    immediate: false,
  })
  tokenInitialChanged() {
    this.setSelectedInitial();
  }
  @Watch("selectedLocal", {
    deep: true,
    immediate: false,
  })
  selectedLocalChanged(newValue: any) {
    this.$emit("selected", newValue);
  }
  @Watch("isTokenDialogVisible", {
    deep: true,
    immediate: false,
  })
  isTokenDialogVisibleChanged(newValue: any) {
    if (!newValue) {
      this.tokenReplace = null;
    }
  }

  setSelectedLocal(data: Array<ITokenAndAmount>) {
    this.$set(this, "selectedLocal", data);
  }

  get chainId(): number | null {
    return chainHelper.network?.chainId ?? null;
  }
  /**
   * Set initial selection of tokens,
   * to the updated tokens of the chain we've just switched to.
   */
  @Watch("chainId", {
    deep: false,
    immediate: false,
  })
  chainIdChanged() {
    if (this.tokenInitial) {
      this.setSelectedLocal([
        {
          token: this.tokenInitial,
        },
      ]);
    }
  }
  setSelectedInitial() {
    if (this.selected && this.selected.length) {
      this.setSelectedLocal(this.selected);
      return;
    }
    if (this.tokenInitial) {
      this.setSelectedLocal([
        {
          token: this.tokenInitial,
        },
      ]);
    }
  }

  setTokenAmount(index: number, amount: number) {
    this.selectedLocal = this.selectedLocal.map(
      (itemValue: any, itemIndex: number) => {
        if (index === itemIndex) {
          return {
            ...itemValue,
            amount,
          };
        }
        return itemValue;
      }
    );
  }

  addToken(token: IToken) {
    this.selectedLocal = [
      ...this.selectedLocal,
      {
        token: token,
      },
    ];
  }
  removeToken(token: IToken) {
    this.selectedLocal = this.selectedLocal.filter((item: ITokenAndAmount) => {
      return item.token?.symbol !== token.symbol;
    });
  }

  getSelectedTokens(): Array<IToken> {
    return this.selectedLocal.map((val) => val.token!);
  }

  setSelectedFromDialog(tokenSelected: IToken | null) {
    if (this.tokenReplace) {
      if (tokenSelected) {
        this.removeToken(this.tokenReplace);
        this.addToken(tokenSelected);
      } else {
        this.removeToken(this.tokenReplace);
      }
    } else {
      if (tokenSelected) {
        this.addToken(tokenSelected);
      }
    }
  }

  getTokenAmount(token: IToken): number | undefined {
    const foundTokenIndex = this.selectedLocal.findIndex((val) => {
      return val.token?.symbol === token.symbol;
    });
    if (foundTokenIndex == -1) return undefined;
    return this.selectedLocal[foundTokenIndex].amount;
  }
}
