
























































import { ITool } from "@/store/tools";
import ExpiresInInput from "./ExpiresInInput.vue";
import { ITradeCreateOptionalSubmit } from "@/store/trades";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    ExpiresInInput,
  },
})
export default class TradeCreateOptional extends Vue {
  @Prop({ type: Number, required: false, default: null })
  expireIn!: number | null;
  @Prop({ type: String, required: false, default: null })
  targetAddress!: string | null;
  @Prop({ type: Object, required: false, default: null })
  tool!: ITool | null;
  @Prop({ type: Array, required: true, default: undefined })
  tools!: Array<ITool>;

  mounted() {
    this.expireInVal = this.expireIn;
    this.targetAddressVal = this.targetAddress;
    this.toolVal = this.tool;
    if (!this.toolVal && this.tools && this.tools.length) {
      this.toolVal = this.tools[0] ?? null;
    }
  }

  /**
   * Expire In
   */
  expireInVal: number | null = null;

  /**
   * Target Address
   */
  targetAddressVal: string | null = null;
  targetAddressRules = [];

  /**
   * Tool
   */
  toolVal: ITool | null = null;
  toolRules = [(v: any) => !!v || "Tool is required"];

  @Watch("toolVal", {
    deep: true,
    immediate: false,
  })
  toolValChanged() {
    this.submit();
  }
  @Watch("expireInVal", {
    deep: true,
    immediate: false,
  })
  expireInValChanged() {
    this.submit();
  }
  @Watch("targetAddressVal", {
    deep: true,
    immediate: false,
  })
  targetAddressValChanged() {
    this.submit();
  }

  /**
   * Submit
   */
  submit() {
    const data: ITradeCreateOptionalSubmit = {
      tool: this.toolVal ?? undefined,
      expireIn: this.expireInVal ?? undefined,
      targetAddress: this.targetAddressVal ?? undefined,
    };
    this.$emit("submit", data);
  }

  cancel() {
    this.$emit("cancel");
  }
}
