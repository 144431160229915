




































































import {
  ITradeCreateOptionalSubmit,
  ITradeCreateRequiredSubmit,
} from "@/store/trades";
import {
  ITransaction,
  TransactionStatus,
  ITransactionUpdate,
} from "@/store/transactions/types";
import BigNumber from "bignumber.js";
import NotificationHelper, {
  NotificationType,
} from "@/helpers/notification.helper";
import { Action, Getter } from "vuex-class";
import ERC20Helper from "@/helpers/erc20.helper";
import { ADDRESS_NULL } from "@/helpers/constants";
import TokenHeader from "../Token/TokenHeader.vue";
import EthereumHelper from "@/helpers/ethereum.helper";
import { Component, Prop, Vue } from "vue-property-decorator";
import ERC20AmountHelpers from "@/helpers/erc20Amounts.helper";
import TokensHelper, { IToken, ITokenAndAmount } from "@/store/tokens";
import TransactionReceiptHelper from "@/helpers/transactionReceipt.helper";
@Component({
  components: {
    TokenHeader,
  },
})
export default class TradeCreateLoading extends Vue {
  @Prop({ type: Object, required: true, default: {} })
  requiredData!: ITradeCreateRequiredSubmit;
  @Prop({ type: Object, required: true, default: {} })
  optionalData!: ITradeCreateOptionalSubmit;

  /**
   * Store
   * Used to get and update current state of transactions.
   */
  @Getter("transactions", { namespace: "transactions" })
  transactions?: Array<ITransaction>;
  @Action("setTransactions", { namespace: "transactions" })
  setTransactions!: (payload?: Array<ITransaction>) => any;
  @Action("addTransaction", { namespace: "transactions" })
  addTransaction!: (payload: ITransaction) => ITransaction;
  @Action("updateTransaction", { namespace: "transactions" })
  updateTransaction!: (status: ITransactionUpdate) => ITransaction;

  mounted() {
    this.submit();
    this.setTransactions([]);
  }

  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  get signerAddress() {
    return this.ethereumHelper.signerAddress;
  }

  getHumanStatus(status: TransactionStatus) {
    if (status === "approve_required") {
      return "Please approve the transaction.";
    } else if (status === "approve_rejected") {
      return "Transaction rejected.";
    } else if (status === "approve_done") {
      return "Transaction approved.";
    } else if (status === "mining") {
      return "In progress, please wait.";
    } else if (status === "mined") {
      return "Done.";
    }
  }

  /**
   * Create
   *
   * For each token on the left side:
   * Create new Trade,
   * containing all tokens from the right side.
   */
  async submit() {
    for (const tokenLeft of this.requiredData!.giveTokens!) {
      // Create Trade
      try {
        await this.tradeCreate(
          tokenLeft,
          this.requiredData!.receiveTokens!,
          this.optionalData?.targetAddress,
          this.optionalData?.expireIn
        );
        this.success();
      } catch (error: any) {
        this.resetViews();
        // Notification
        NotificationHelper.publish(
          "Error: Trade not Created",
          error,
          NotificationType.ERROR
        );
      }
    }
  }

  /**
   * tradeCreate
   *
   * @returns {Promise<BigNumber|undefined}
   */
  async tradeCreate(
    tokenLeft: ITokenAndAmount,
    tokensRight: Array<ITokenAndAmount>,
    userAccepter?: string,
    blockExpiresIn?: number
  ): Promise<BigNumber | undefined> {
    // Validation
    if (!this.ethereumHelper.contract) {
      throw Error("Contract not initialized.");
    }

    /**
     * Adjust tokenLeft amount
     */
    let additional = {};
    const tokenLeftAmount: string =
      ERC20AmountHelpers.calculateAmountBasedOnDecimals(
        tokenLeft.amount!,
        tokenLeft.token!.decimals ?? 18
      ).toFixed();
    if (tokenLeft.token!.address === ADDRESS_NULL) {
      additional = {
        ...additional,
        value: tokenLeftAmount,
      };
    } else {
      try {
        // Approve the Transfer to the Contract
        const erc20Helper = new ERC20Helper(tokenLeft.token!.address!);
        (await erc20Helper.approve(new BigNumber(tokenLeftAmount))) ?? 0;
      } catch (error) {
        console.error("Approve Error", error);
        throw error;
      }
    }

    /**
     * Set tokensRightAmounts
     */
    const tokensRightAmounts: string[] = [];
    const tokensRightAddresses: string[] = [];
    for (const item of tokensRight) {
      if (!item.token) continue;
      if (!item.amount) continue;
      if (!item.token.address) continue;

      // Address
      tokensRightAddresses.push(item.token?.address);

      // Amount
      tokensRightAmounts.push(
        ERC20AmountHelpers.calculateAmountBasedOnDecimals(
          item.amount,
          item.token.decimals ?? 18
        ).toFixed()
      );
    }

    /**
     * Send Transaction
     */
    this.addTransaction({
      contractAddress: this.ethereumHelper.contract.address,
      contractIsErc20: false,
      status: TransactionStatus.APPROVE_REQUIRED,
    });
    let tx;
    try {
      tx = await this.ethereumHelper.contract!.tradeCreate(
        tokenLeft.token!.address,
        tokensRightAddresses,
        tokenLeftAmount,
        tokensRightAmounts,
        userAccepter ? userAccepter : ADDRESS_NULL,
        blockExpiresIn ? blockExpiresIn : "0",
        additional
      );
      this.updateTransaction({
        contractAddress: this.ethereumHelper.contract.address,
        transaction: {
          status: TransactionStatus.APPROVE_DONE,
        },
      });
    } catch (error) {
      this.updateTransaction({
        contractAddress: this.ethereumHelper.contract.address,
        transaction: {
          status: TransactionStatus.APPROVE_REJECTED,
        },
      });
      throw error;
    }

    this.updateTransaction({
      contractAddress: this.ethereumHelper.contract.address,
      transaction: {
        status: TransactionStatus.MINING,
      },
    });
    let rc = await tx.wait();
    this.updateTransaction({
      contractAddress: this.ethereumHelper.contract!.address,
      transaction: {
        status: TransactionStatus.MINED,
      },
    });
    /**
     * Get tradeId
     */
    const tradeId: BigNumber =
      TransactionReceiptHelper.getEventArgumentValueFromEvents(
        rc.events,
        "TradeCreated",
        "tradeID"
      );
    // Notification
    NotificationHelper.publish(
      "Trade Created",
      undefined,
      NotificationType.SUCCESS,
      `/trade/${tradeId.toString()}`,
      "View the Trade"
    );
    return undefined;
  }

  getTokenAmount(token: IToken): number | undefined {
    const found = this.requiredData.giveTokens?.find(
      (item) => item.token?.address === token.address
    );
    if (found) return found.amount;
    return undefined;
  }
  getTokenByAddress(val: string): IToken | undefined {
    return TokensHelper.getTokenByAddress(val);
  }

  resetViews() {
    this.$emit("resetViews");
  }
  success() {
    this.$emit("success");
  }
}
