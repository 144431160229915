
























































export enum TradeListFilterType {
  ANY = "any",
  ACTIVE = "active",
  EXPIRED = "expired",
}
export interface ITradeListFilterSubmit {
  type?: TradeListFilterType;
  tokens?: Array<IToken>;
}
import { Getter } from "vuex-class";
import { IToken } from "@/store/tokens";
import TokenSelect from "../Token/TokenSelect.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    TokenSelect,
  },
})
export default class TradeListFilter extends Vue {
  @Getter("tokens", { namespace: "tokens" })
  getterTokens!: (onlyActiveLists: boolean) => Array<IToken> | undefined;

  /**
   * TradeListFilterType
   */
  type: TradeListFilterType = TradeListFilterType.ANY;
  types = Object.values(TradeListFilterType);

  /**
   * Tokens
   */
  get tokens(): Array<IToken> | undefined {
    return this.getterTokens(false);
  }

  /**
   * Selected Tokens
   */

  selectedTokens: Array<IToken> = [];
  setSelectedTokens(data: Array<IToken>) {
    this.$set(this, "selectedTokens", data);
  }

  @Watch("type", {
    deep: true,
    immediate: false,
  })
  typeChanged(newValue: any) {
    this.submit();
  }
  @Watch("tokens", {
    deep: true,
    immediate: false,
  })
  tokensChanged(newValue: any) {
    this.submit();
  }

  /**
   * Submit
   */
  submit() {
    const data = {
      type: this.type,
      tokens: this.selectedTokens,
    };
    this.$emit("submit", data);
  }
}
