

























import { ITrade } from "@/store/offers";
import TradeItemAccept from "./TradeItemAccept.vue";
import TradeItemCancel from "./TradeItemCancel.vue";
import EthereumHelper from "@/helpers/ethereum.helper";
import TradeItemHelper from "@/helpers/tradeItem.helper";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TradeItemActions",
  components: {
    TradeItemAccept,
    TradeItemCancel,
  },
})
export default class TradeItemActions extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;
  @Prop({ required: true, default: null })
  selectedIndex!: number | null;
  @Prop({ required: false, default: undefined })
  blockCurrentNumber!: number | undefined;

  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  get isExpired(): boolean {
    return TradeItemHelper.isExpired(this.tradeItem, this.blockCurrentNumber);
  }

  get signerAddress() {
    return this.ethereumHelper.signerAddress;
  }
  get isCreator() {
    if (!this.signerAddress) return false;
    return this.tradeItem.userCreator === this.signerAddress;
  }

  get isCanceled() {
    return TradeItemHelper.isCanceled(this.tradeItem);
  }
  get isCancelVisible() {
    if (this.isCanceled) {
      return true;
    }
    if (!this.isCreator) {
      return false;
    }
    return !this.isAccepted;
  }

  get isAccepted() {
    return TradeItemHelper.isAccepted(this.tradeItem);
  }
  get isAcceptVisible() {
    if (this.isCreator) {
      if (this.isAccepted) {
        // Show to the Creator, if Accepted
        return true;
      } else {
        // Else hide
        return false;
      }
    } else {
      return !this.isCanceled;
    }
  }
}
