





















































































import {
  ITradeCreateOptionalSubmit,
  ITradeCreateRequiredSubmit,
} from "@/store/trades";
import {
  TradeListFilterType,
  ITradeListFilterSubmit,
} from "./TradeListFilter.vue";
import { Getter } from "vuex-class";
import NotificationHelper, {
  NotificationType,
} from "@/helpers/notification.helper";
import TradeList from "./TradeList.vue";
import tools, { ITool } from "@/store/tools";
import TradesSuggested from "./TradesSuggested.vue";
import EthereumHelper from "@/helpers/ethereum.helper";
import TradeCreateLoading from "./TradeCreateLoading.vue";
import TradeCreateSuccess from "./TradeCreateSuccess.vue";
import TradeCreateRequired from "./TradeCreateRequired.vue";
import TradeCreateOptional from "./TradeCreateOptional.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import UserTokensHelper from "@/store/userTokens/userTokens.helper";
import DialogWalletConnect from "../General/DialogWalletConnect.vue";
import TradeListHelper from "@/helpers/tradeList.helper";
import { ITrade } from "@/store/offers";
@Component({
  components: {
    TradeList,
    TradesSuggested,
    TradeCreateLoading,
    TradeCreateSuccess,
    TradeCreateRequired,
    TradeCreateOptional,
    DialogWalletConnect,
  },
})
export default class TradeCreate extends Vue {
  isSuggestedVisible = false;
  listFilter: ITradeListFilterSubmit = {};
  blockCurrentNumber: number | null = null;
  get tradesFiltered() {
    return TradeListHelper.filterTradeList(
      this.trades ?? [],
      this.listFilter,
      this.blockCurrentNumber ?? undefined,
      true
    );
  }
  @Getter("trades", { namespace: "trades" })
  trades: Array<ITrade> | undefined;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  mounted() {
    this.setBlockCurrentNumber();
  }

  async setBlockCurrentNumber() {
    this.blockCurrentNumber =
      (await this.ethereumHelper.provider?.getBlockNumber()) ?? null;
  }

  toggleIsOptionalInputVisible() {
    this.isOptionalInputVisible = !this.isOptionalInputVisible;
    // TODO: Check if this changes anything (Submit flow)
    this.isSuggestedVisible = false;
  }
  toggleIsSuggestedVisible() {
    this.isSuggestedVisible = !this.isSuggestedVisible;
    // TODO: Check if this changes anything (Submit flow)
    this.isOptionalInputVisible = false;
  }

  get tokens() {
    return this.tokensGetter(true);
  }
  get signerAddress() {
    return this.ethereumHelper.signerAddress;
  }
  get isSubmitDisabled(): boolean {
    return !this.isInputValid(this.requiredData, this.optionalData);
  }
  @Getter("tokens", { namespace: "tokens" }) tokensGetter!: (
    onlyActiveLists: boolean
  ) => any;
  tools: Array<ITool> = tools;

  /**
   * Screens
   *
   * Loading,
   * Success
   */
  isLoadingVisible = false;
  isRequiredInputVisible = true;
  isOptionalInputVisible = false;
  isSuccessScreenVisible = false;

  /**
   * Required Data
   */
  requiredData: ITradeCreateRequiredSubmit = {};
  async updateRequiredData(data: ITradeCreateRequiredSubmit) {
    this.requiredData = { ...data };
  }

  /**
   * Optional Data
   */
  isOptionalInputSubmitted = false;
  optionalData: ITradeCreateOptionalSubmit = {};
  updateOptionalData(data: ITradeCreateOptionalSubmit) {
    this.optionalData = { ...data };
  }

  /**
   * Watchers
   */
  @Watch("tradesFiltered", {
    deep: false,
    immediate: false,
  })
  tradesFilteredChanged() {
    this.isSuggestedVisible = false;
  }
  @Watch("requiredData", {
    deep: false,
    immediate: false,
  })
  requiredDataChanged() {
    // Refetch and filter Suggested Trades
    if (
      this.requiredData.receiveTokens &&
      this.requiredData.receiveTokens.length
    ) {
      this.listFilter = {
        type: TradeListFilterType.ACTIVE,
        tokens: this.requiredData.receiveTokens.map((item) => item.token!),
      };
    } else {
      this.listFilter = {
        type: TradeListFilterType.ACTIVE,
        tokens: [],
      };
    }
  }
  @Watch("isOptionalInputSubmitted", {
    deep: false,
    immediate: false,
  })
  isOptionalInputSubmittedChanged() {
    this.checkStateAndTriggerPublish();
  }
  @Watch("signerAddress", {
    deep: false,
    immediate: false,
  })
  signerAddressChanged() {
    this.checkStateAndTriggerPublish();
  }

  /**
   * Triger handleSubmit() if:
   * - User is authenticated,
   * - Required and Optional Screen are Submitted,
   * - User Token Amount is Fetched.
   */
  checkStateAndTriggerPublish() {
    if (this.signerAddress && this.isOptionalInputSubmitted) {
      this.handleSubmit();
    }
  }

  /**
   * Input Validation
   *
   * Both sides have, at least 1, Token(s) and Amount(s) set.
   */
  isInputValid(
    requiredData?: ITradeCreateRequiredSubmit,
    optionalData?: ITradeCreateOptionalSubmit
  ): boolean {
    if (!requiredData) {
      return false;
    }

    if (!requiredData.giveTokens || !requiredData.giveTokens.length) {
      return false;
    }
    for (const tokenAndAmount of requiredData.giveTokens) {
      if (!tokenAndAmount.token) return false;
      if (!tokenAndAmount.token.address) return false;
      if (!tokenAndAmount.amount) return false;
    }

    if (!requiredData.receiveTokens || !requiredData.receiveTokens.length) {
      return false;
    }
    for (const tokenAndAmount of requiredData.receiveTokens) {
      if (!tokenAndAmount.token) return false;
      if (!tokenAndAmount.token.address) return false;
      if (!tokenAndAmount.amount) return false;
    }

    return true;
  }

  /**
   * Click Submit Button
   */
  async handleSubmitButtonClick() {
    this.isOptionalInputSubmitted = true;
  }

  /**
   * Submit
   */
  async handleSubmit() {
    if (!this.isInputValid(this.requiredData, this.optionalData)) {
      return;
    }
    if (!(await this.hasUserEnoughTokenBalance(this.requiredData!))) {
      return;
    }
    this.showLoading();
  }

  /**
   * Check user balance
   */
  async hasUserEnoughTokenBalance(
    requiredData: ITradeCreateRequiredSubmit
  ): Promise<boolean> {
    for (const tokenAndAmount of requiredData.giveTokens!) {
      // Fetch User Tokens Balance
      await UserTokensHelper.fetchUserTokenAmount(
        tokenAndAmount.token!,
        this.signerAddress
      );

      // Check User Token Balance
      if (!UserTokensHelper.hasEnough(tokenAndAmount)) {
        // Notification
        NotificationHelper.publish(
          "Error: Trade not Created",
          "Not enough funds for " + tokenAndAmount.token?.symbol,
          NotificationType.ERROR
        );
        this.resetViews();
        return false;
      }
    }
    return true;
  }

  /**
   * Rest views
   */
  resetViews() {
    this.isRequiredInputVisible = true;
    this.isOptionalInputVisible = false;
    this.isOptionalInputSubmitted = false;
    this.isLoadingVisible = false;
    this.isSuccessScreenVisible = false;
  }

  /**
   * Reset data
   */
  resetData() {
    this.updateRequiredData({});
    this.updateOptionalData({});
  }

  /**
   * Show loading screen
   */
  showLoading() {
    this.isLoadingVisible = true;
    this.isRequiredInputVisible = false;
    this.isOptionalInputVisible = false;
    this.isSuccessScreenVisible = false;
  }

  /**
   * Show success screen
   */
  showSuccess() {
    this.isSuccessScreenVisible = true;
    this.isLoadingVisible = false;
    this.isRequiredInputVisible = false;
    this.isOptionalInputVisible = false;
  }
}
