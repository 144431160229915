





































import NotificationHelper, {
  NotificationType,
} from "@/helpers/notification.helper";
import { ITrade } from "@/store/offers";
import { BigNumber } from "bignumber.js";
import tokensHelper from "@/store/tokens";
import { Action, Getter } from "vuex-class";
import { TradeAcceptDto } from "@/store/trades/types";
import ethereumHelper from "@/helpers/ethereum.helper";
import TradeItemHelper from "@/helpers/tradeItem.helper";
import { Component, Prop, Vue } from "vue-property-decorator";
import ERC20AmountHelpers from "@/helpers/erc20Amounts.helper";
import TradeItemDialogAccept from "./TradeItemDialogAccept.vue";
import UserTokensHelper from "@/store/userTokens/userTokens.helper";
import DialogWalletConnect from "../General/DialogWalletConnect.vue";
@Component({
  name: "TradeItemAccept",
  components: {
    DialogWalletConnect,
    TradeItemDialogAccept,
  },
})
export default class TradeItemAccept extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;
  @Prop({ required: true, type: Number, default: null })
  selectedIndex!: number;
  @Prop({ required: false, default: undefined })
  blockCurrentNumber!: number | undefined;

  @Action("tradeAccept", { namespace: "trades" }) tradeAccept!: (
    payload: TradeAcceptDto
  ) => any;
  @Getter("tradeIsAcceptPending", { namespace: "trades" })
  tradeIsAcceptPending!: (id: BigNumber) => boolean;

  get signerAddress() {
    return ethereumHelper.signerAddress;
  }

  isDialogAcceptVisible = false;
  get isAcceptPending() {
    return this.tradeIsAcceptPending(this.tradeItem.tradeID!);
  }
  get isAccepted() {
    return TradeItemHelper.isAccepted(this.tradeItem);
  }
  get isAcceptDisabled() {
    if (this.isAcceptPending) return true;
    if (TradeItemHelper.isCreator(this.tradeItem, this.signerAddress))
      return true;
    if (this.isAccepted) return true;
    if (TradeItemHelper.isCanceled(this.tradeItem)) return true;
    return false;
  }
  get acceptText() {
    if (this.isAccepted) {
      return "Accepted";
    }
    return "Accept";
  }

  async handleAccept() {
    if (this.selectedIndex === null) return;
    /**
     * Get Selected tokenRight
     */
    const selectedTokenRightAddress =
      this.tradeItem!.tokensRight![this.selectedIndex];
    const selectedTokenRightToken = tokensHelper.getTokenByAddress(
      selectedTokenRightAddress
    )!;

    // Find TokenRight by tokenRightSelectedIndex
    const foundTokenRightAmount =
      this.tradeItem!.tokensRightAmounts![this.selectedIndex];

    const tokenAmount =
      ERC20AmountHelpers.transformAmountToTokensBasedOnDecimals(
        new BigNumber(foundTokenRightAmount.toString()),
        selectedTokenRightToken.decimals
      );

    // Fetch User Tokens Balance
    await UserTokensHelper.fetchUserTokenAmount(
      selectedTokenRightToken,
      this.signerAddress
    );
    if (
      !UserTokensHelper.hasEnough({
        token: selectedTokenRightToken,
        amount: tokenAmount.toNumber(),
      })
    ) {
      // Notification
      NotificationHelper.publish(
        "Error: Trade not Accepted",
        "Not enough funds for " + selectedTokenRightToken?.symbol,
        NotificationType.ERROR
      );
      return;
    }

    /**
     * Send Transaction
     */
    await this.tradeAccept({
      trade: this.tradeItem,
      tokenRightSelected: selectedTokenRightToken,
    });
  }
}
