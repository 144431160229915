










import { ITrade } from "@/store/offers";
import SecondsToHMS from "../General/SecondsToHMS.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import TradeItemHelper, { IExpiresAtTime } from "@/helpers/tradeItem.helper";
@Component({
  name: "TradeItemExpiresIn",
  components: {
    SecondsToHMS,
  },
})
export default class TradeItemExpiresIn extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;
  @Prop({ required: false, default: undefined })
  blockCurrentNumber!: number | undefined;

  get timeExpires(): IExpiresAtTime | null {
    return TradeItemHelper.getExpiresAtTime(
      this.tradeItem,
      this.blockCurrentNumber
    );
  }
}
