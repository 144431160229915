




































































import { ITrade } from "@/store/offers";
import TimeAgo from "../General/TimeAgo.vue";
import UserHelper from "@/helpers/user.helper";
import UserAvatar from "../User/UserAvatar.vue";
import TradeItemCreator from "./TradeItemCreator.vue";
import TradeItemInspect from "./TradeItemInspect.vue";
import TradeItemDetails from "./TradeItemDetails.vue";
import TradeItemActions from "./TradeItemActions.vue";
import SecondsToHMS from "../General/SecondsToHMS.vue";
import TradeItemExpiresIn from "./TradeItemExpiresIn.vue";
import TradeItemTokenLeft from "./TradeItemTokenLeft.vue";
import TradeItemCreatedAt from "./TradeItemCreatedAt.vue";
import TradeItemTokensRight from "./TradeItemTokensRight.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TradeItemMobile",
  components: {
    TimeAgo,
    UserAvatar,
    SecondsToHMS,
    TradeItemActions,
    TradeItemInspect,
    TradeItemCreator,
    TradeItemDetails,
    TradeItemCreatedAt,
    TradeItemExpiresIn,
    TradeItemTokenLeft,
    TradeItemTokensRight,
  },
})
export default class TradeItemMobile extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;
  @Prop({ required: false, default: undefined })
  blockCurrentNumber!: number | undefined;
  @Prop({ type: Boolean, required: false, default: false })
  showOnlyBasicColumns!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isDetailsHidden!: boolean;

  selectedIndex: number | null = null;

  get addressShort(): string | null {
    return UserHelper.shortifyAddress(this.tradeItem.userCreator);
  }
}
