var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{class:{
      isHovered: hover,
      'cursor-pointer': _vm.isEditable,
      'transparentBackground d-inline-block py-0 px-0': true,
      isRounded: true,
    }},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-avatar',{staticClass:"d-inline-block",attrs:{"size":_vm.size}},[(_vm.imageUrl)?_c('v-img',{attrs:{"src":_vm.imageUrl}}):_vm._e()],1),(_vm.isNameVisible)?_c('h3',{staticClass:"d-inline-block ml-4"},[_vm._v(" "+_vm._s(_vm.name)+" "),(_vm.isEditable)?_c('span',[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary","large":""},on:{"click":function($event){$event.preventDefault();return _vm.edit()}}},[_vm._v(" mdi-chevron-down ")])],1):_vm._e()]):_vm._e(),(_vm.signerAddress && _vm.isBalanceVisible)?_c('div',[_c('v-divider',{staticClass:"my-4"}),_c('h4',{staticClass:"mt-3"},[_vm._v("Balance: "+_vm._s(_vm.balance))])],1):_vm._e()],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }