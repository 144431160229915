

















import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "ServiceWorkerUpdate",
})
export default class ServiceWorkerUpdate extends Vue {
  isUpdateAvailable = false;
  isWindowReloading = false;
  serviceWorkerRegistration?: ServiceWorkerRegistration;

  created() {
    document.addEventListener(
      "serviceWorkerUpdated",
      this.serviceWorkerUpdatedHandler,
      {
        once: true,
      }
    );
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.isWindowReloading) return;
      this.isWindowReloading = true;
      window.location.reload();
    });
  }

  serviceWorkerUpdatedHandler(event: any) {
    this.isUpdateAvailable = true;
    this.serviceWorkerRegistration = event.detail;
  }

  appUpdate() {
    this.isUpdateAvailable = false;
    if (!this.serviceWorkerRegistration) return;
    if (!this.serviceWorkerRegistration.waiting) return;
    this.serviceWorkerRegistration.waiting.postMessage({
      type: "SKIP_WAITING",
    });
  }
}
