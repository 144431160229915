












































import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "SnackbarTestingTips",
})
export default class SnackbarTestingTips extends Vue {
  isVisible = true;
  close() {
    this.isVisible = false;
  }
}
