import tokensList from "./tokens.list";
import chainHelper from "@/helpers/chain.helper";
import tokenLists from "./tradeLists/tokenLists";

/**
 * Tokens
 *
 * Interface and List
 * TODO: To be replaced with a valid, whitelisted set,
 * hardcoded or from the API.
 */

/**
 * Token Interface
 */
export interface IToken {
  chainId: number;
  address: string;

  name: string;
  symbol: string;

  decimals: number;

  logoURI: string;
}

export interface ITokenAndAmount {
  token?: IToken;
  amount?: number;
}

import store from "./index";

export class TokensHelper {
  getTokens(onlyActiveLists = true): Array<IToken> {
    if (!chainHelper.network) throw Error("Network not selected");
    const chainId = chainHelper.network.chainId;
    // TODO: Pass chainId
    return store.getters["tokens/tokens"](onlyActiveLists);
    // return store.getters["tokens/tokens"](chainId!);
  }
  getTokenByAddress(address: string): IToken | undefined {
    if (!chainHelper.network) throw Error("Network not selected");
    const tokens = this.getTokens(false);
    return tokens.find((token: IToken) => {
      return token.address === address;
    });
  }
}

export default new TokensHelper();
