import axios from "axios";
import { IToken } from "../tokens";
import tokenLists from "./tokenLists.list";

/**
 * Token Interface
 */
export interface ITokenList {
  /**
   * Standard attrs
   */
  name?: string;
  tokens: Array<IToken>;
  logoURI?: string;
  // URL of the list, to be fetched
  jsonURI?: string;

  /**
   * Our, Custom attrs
   */
  id?: number;
  isActive?: boolean;
  isUserList?: boolean;
}

export class TokenListsHelper {
  /**
   * Fetch List By URL
   */
  async fetchListByURL(jsonURI: string): Promise<any> {
    const response = await axios.get(jsonURI);
    const listFetched = response.data;
    return listFetched;
  }

  /**
   * Fetch tokens for each list
   * Store locally
   */
  async fetchLists(): Promise<Array<ITokenList>> {
    const listsFetched: Array<ITokenList> = [];
    if (!tokenLists) return listsFetched;
    for (const list of tokenLists) {
      let listFetched;
      if (list.jsonURI) {
        listFetched = await this.fetchListByURL(list.jsonURI);
        listFetched.isActive = true;
      } else {
        listFetched = {
          name: list.name,
          tokens: list.tokens,
          logoURI: list.logoURI,
          isActive: true,
          isUserList: list.isUserList,
        };
      }
      listsFetched.push(listFetched);
    }
    return listsFetched;
  }
}

export default new TokenListsHelper();
