






import { ITrade } from "@/store/offers";
import { BigNumber as BigNumberEthers } from "ethers";
import TradeItemHelper from "@/helpers/tradeItem.helper";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TradeItemInspect",
  components: {
    //
  },
})
export default class TradeItemInspect extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;

  mounted() {
    this.setTransactionUrl();
  }

  /**
   * Transaction URL
   * Linked to the Explorer
   */
  transactionUrl: string | null = null;
  async setTransactionUrl() {
    if (this.isAccepted) {
      this.transactionUrl = await TradeItemHelper.getAcceptedExplorerLink(
        BigNumberEthers.from(this.tradeItem.tradeID!)
      );
    } else if (this.isCanceled) {
      this.transactionUrl = await TradeItemHelper.getCancelledExplorerLink(
        BigNumberEthers.from(this.tradeItem.tradeID!)
      );
    } else {
      this.transactionUrl = await TradeItemHelper.getCreatedExplorerLink(
        BigNumberEthers.from(this.tradeItem.tradeID!)
      );
    }
  }

  get isCanceled() {
    return TradeItemHelper.isCanceled(this.tradeItem);
  }
  get isAccepted() {
    return TradeItemHelper.isAccepted(this.tradeItem);
  }
}
