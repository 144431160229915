























import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    //
  },
})
export default class TradeCreateSuccess extends Vue {
  reset() {
    this.$emit("reset");
  }
}
