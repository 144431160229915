import { GetterTree } from "vuex";
import { RootState } from "../types";
import { NotificationsState } from "./types";
import { INotificationItem } from "@/helpers/notification.helper";

export const getters: GetterTree<NotificationsState, RootState> = {
  notifications(state): Array<INotificationItem> | undefined {
    const { notifications } = state;
    return notifications;
  },
  notification:
    (getters) =>
    (id: number): INotificationItem | undefined => {
      const notifications = getters["notifications"];
      if (!notifications) return undefined;
      return notifications.find((item: INotificationItem) => {
        return item.data?.id === id;
      });
    },
};
