






import { ITrade } from "@/store/offers";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TradeItemDetails",
  components: {
    //
  },
})
export default class TradeItemDetails extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;
}
