

















































































export interface ITradeListOptions {
  page?: number;
  itemsPerPage?: number;
}
import TradeListFilter, {
  ITradeListFilterSubmit,
  TradeListFilterType,
} from "./TradeListFilter.vue";
import TradeItem from "./TradeItem.vue";
import { ITrade } from "@/store/offers";
import TradeItemMobile from "./TradeItemMobile.vue";
import EthereumHelper from "@/helpers/ethereum.helper";
import TradeListHelper from "@/helpers/tradeList.helper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    TradeItem,
    TradeItemMobile,
    TradeListFilter,
  },
})
export default class TradeList extends Vue {
  @Prop({ type: Array, required: true, default: [] })
  list!: Array<ITrade>;
  @Prop({ type: Number, required: true, default: 0 })
  listItemsTotal!: number;
  @Prop({ type: Boolean, required: false, default: false })
  isLoading!: boolean;
  @Prop({ type: Boolean, required: false, default: true })
  isFilterVisible!: boolean;
  @Prop({ type: Object, required: false, default: {} })
  listFilterProp!: ITradeListFilterSubmit;
  @Prop({ type: Boolean, required: false, default: false })
  forceTableView!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  showOnlyBasicColumns!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  hideHeader!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  hideFooter!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isHistoryAllowed!: boolean;

  get envPaginationPerPage(): number {
    return parseInt(process.env.VUE_APP_PAGINATION_PER_PAGE ?? "25");
  }
  get tablePerPageOptions() {
    return [this.envPaginationPerPage, -1];
  }
  options: ITradeListOptions = {};
  listLocal: Array<ITrade> = [];
  listFilter: ITradeListFilterSubmit = {};
  listItemsTotalLocal = 0;
  blockCurrentNumber: number | null = null;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  mounted() {
    this.setBlockCurrentNumber();
    this.options = {
      page: 1,
      itemsPerPage: this.envPaginationPerPage,
    };
  }

  async setBlockCurrentNumber() {
    this.blockCurrentNumber =
      (await this.ethereumHelper.provider?.getBlockNumber()) ?? null;
  }

  @Watch("listItemsTotal", {
    deep: true,
    immediate: true,
  })
  listItemsTotalChanged(newValue: any) {
    this.listItemsTotalLocal = this.isFilterActive(this.listFilter)
      ? this.listLocal.length
      : this.listItemsTotal;
  }
  @Watch("list", {
    deep: true,
    immediate: true,
  })
  listChanged(newValue: any) {
    this.setListLocal();
  }
  @Watch("listFilterProp", {
    deep: true,
    immediate: false,
  })
  listFilterPropChanged(newValue: any) {
    this.listFilter = newValue;
  }

  @Watch("listFilter", {
    deep: true,
    immediate: false,
  })
  listFilterChanged(newValue: any) {
    if (this.isFilterActive(newValue)) {
      this.options = {
        ...this.options,
        itemsPerPage: -1,
        page: 1,
      };
    } else {
      this.options = {
        ...this.options,
        itemsPerPage: this.envPaginationPerPage,
        page: 1,
      };
    }
    this.setListLocal();
  }
  @Watch("options", {
    deep: true,
    immediate: false,
  })
  optionsChanged(newValue: ITradeListOptions, oldValue: ITradeListOptions) {
    if (
      newValue.page !== oldValue.page ||
      newValue.itemsPerPage !== oldValue.itemsPerPage
    ) {
      this.fetch(newValue.page, newValue.itemsPerPage);
    }
    this.setListLocal();
  }

  isFilterActive(filterVal: ITradeListFilterSubmit): boolean {
    if (!filterVal) return false;
    if (
      filterVal.type === TradeListFilterType.ACTIVE ||
      filterVal.type === TradeListFilterType.EXPIRED
    )
      return true;
    if (filterVal.tokens && filterVal.tokens.length) return true;
    return false;
  }

  setListFilter($event: any) {
    this.listFilter = { ...$event };
  }
  setListLocal() {
    this.listLocal = TradeListHelper.filterTradeList(
      this.list,
      this.listFilter,
      this.blockCurrentNumber ?? undefined
    );
    this.listItemsTotalLocal = this.isFilterActive(this.listFilter)
      ? this.listLocal.length
      : this.listItemsTotal;
  }

  fetch(page?: number, itemsPerPage?: number) {
    /**
     * Items per page
     * Transformation
     *
     * Vuetify returns -1 if all rows need to be fetched.
     * Transform -1 to 0, so we can send it to Solidity.
     */
    if (itemsPerPage === -1) {
      itemsPerPage = 0;
    }
    this.$emit("fetch", { page, itemsPerPage });
  }
}
