









import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "SecondsToHMS",
})
export default class SecondsToHMS extends Vue {
  @Prop({ type: Number, required: false, default: 0 })
  seconds!: number;
  @Prop({ type: Boolean, required: false, default: false })
  isPast!: number;
}
