






















































import { IToken } from "@/store/tokens";
import { Action, Getter } from "vuex-class";
import chainHelper from "@/helpers/chain.helper";
import ERC20Helper from "@/helpers/erc20.helper";
import { Component, Vue } from "vue-property-decorator";
import { ITokenList } from "@/store/tradeLists/tokenLists";
@Component({
  components: {
    //
  },
})
export default class TokenImportDialog extends Vue {
  dialog = true;

  address: any | null = null;
  logoURI: any | null = null;

  @Getter("userList", { namespace: "tokens" })
  userList: ITokenList | undefined;
  @Action("addListToken", { namespace: "tokens" })
  addListToken!: (payload: { listId: number; token: IToken }) => any;

  get isSubmitDisabled() {
    return !this.address;
  }

  async submit() {
    if (!this.address) return;
    const erc20Helper: ERC20Helper = new ERC20Helper(this.address);
    const name = await erc20Helper.getName();
    const symbol = await erc20Helper.getSymbol();
    const decimals = await erc20Helper.getDecimals();

    // Get listId
    if (!this.userList) return;
    if (!this.userList.id) return;
    const listId = this.userList?.id;

    // Get chainId
    const network = chainHelper.network;
    const chainId = network?.chainId;
    if (!chainId) return;

    this.addListToken({
      listId,
      token: {
        chainId,
        name,
        symbol,
        decimals,
        address: this.address,
        logoURI: this.logoURI,
      },
    });

    this.close();
  }

  close() {
    this.dialog = false;
    this.$emit("close");
  }
}
