



















import { ethers } from "ethers";
import { chains, IChain } from "@/store/chains";
import ChainHelper from "@/helpers/chain.helper";
import { ChainSwitcherStatus } from "@/store/chains/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class NavigationChain extends Vue {
  @Prop({ type: String, required: false, default: "" })
  name!: string;

  chain: IChain | null = null;
  chainHelper: typeof ChainHelper = ChainHelper;

  get chains() {
    return chains;
  }
  get chainCurrent(): ethers.providers.Network | undefined {
    return this.chainHelper.network;
  }

  @Watch("chainCurrent", {
    deep: true,
    immediate: true,
  })
  chainCurrentChanged(newValue: ethers.providers.Network | null) {
    if (newValue) {
      this.chain =
        this.chains.find((chain: IChain) => chain.id === newValue!.chainId) ??
        null;
    }
  }
  @Watch("chain", {
    deep: true,
    immediate: false,
  })
  chainChanged(newValue: IChain | null, oldValue: IChain | null) {
    if (!newValue) return;
    if (!this.chainCurrent || newValue.id !== this.chainCurrent.chainId) {
      this.switchNetwork(newValue, oldValue);
    }
  }

  async switchNetwork(newValue: IChain, oldValue: IChain | null) {
    const result = await this.chainHelper.switchNetwork(newValue);
    if (result !== ChainSwitcherStatus.SUCCESS) {
      /**
       * Set to previous network if result status of the Switch is not SUCCESS
       */
      this.chain = oldValue;
    }
  }
}
