var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","elevate-on-scroll":"","light":"","color":"background"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-toolbar-title',{staticClass:"display-1 font-weight-bold"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'home' }}},[_c('v-img',{attrs:{"src":"/img/theme/logo.svg","height":"32px","contain":"","width":"128px"}})],1)],1)],1),_c('v-spacer'),_c('router-link',{staticClass:"text-decoration-none mr-4",attrs:{"to":{ name: 'home' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('NavLink',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"text":"","large":"","color":isExactActive ? 'primary' : undefined}},[_c('h3',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Trade")]),(hover)?_c('v-img',{staticClass:"ml-2",attrs:{"height":"32px","width":"32","src":"/img/theme/coin_hover.svg"}}):(isExactActive)?_c('v-img',{staticClass:"ml-2",attrs:{"height":"32px","width":"32","src":"/img/theme/coin_active.svg"}}):_c('v-img',{staticClass:"ml-2",attrs:{"height":"32px","width":"32","src":"/img/theme/coin.svg"}})],1)])]}}],null,true)})],1)]}}])}),_c('v-divider',{attrs:{"vertical":""}}),_c('router-link',{staticClass:"text-decoration-none ml-4",attrs:{"to":{ name: 'explore' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('NavLink',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"text":"","large":"","color":isExactActive ? 'primary' : undefined}},[_c('h3',{staticClass:"d-flex align-center"},[(hover)?_c('v-img',{staticClass:"mr-2",attrs:{"height":"32px","width":"32","src":"/img/theme/explorer_hover.svg"}}):(isExactActive)?_c('v-img',{staticClass:"mr-2",attrs:{"height":"32px","width":"32","src":"/img/theme/explorer_active.svg"}}):_c('v-img',{staticClass:"mr-2",attrs:{"height":"32px","width":"32","src":"/img/theme/explorer.svg"}}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Explore")])],1)])]}}],null,true)})],1)]}}])}),_c('v-spacer'),_c('v-btn',{attrs:{"group":"","text":"","flat":""}},[_c('NavigationChain'),_c('NavigationUser')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }