import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { RootState } from "../types";
import { ChainsState } from "./types";
import { mutations } from "./mutations";

export const state: ChainsState = {
  chainSwitcherStatus: undefined,
};

const namespaced = true;

export const chains: Module<ChainsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
