var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFilterVisible)?_c('TradeListFilter',{staticClass:"pb-4",on:{"submit":function($event){return _vm.setListFilter($event)}}}):_vm._e(),(_vm.isLoading && (!_vm.listLocal || !_vm.listLocal.length))?_c('v-alert',{staticClass:"text-center py-16",attrs:{"color":"dark"}},[_c('v-progress-circular',{staticClass:"my-8",attrs:{"size":48,"color":"white","indeterminate":""}}),_c('h2',[_vm._v("Loading Trades...")])],1):(_vm.listLocal && _vm.listLocal.length)?_c('v-data-table',{staticClass:"theme-table",attrs:{"fixed-header":"","items":_vm.listLocal,"loading":_vm.isLoading,"mobile-breakpoint":"1024","footer-props":{
      'disable-items-per-page': true,
      'items-per-page-options': _vm.tablePerPageOptions,
    },"hide-default-footer":_vm.hideFooter,"options":_vm.options,"server-items-length":_vm.listItemsTotalLocal},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var isMobile = ref.isMobile;
return [(!isMobile && !_vm.hideHeader)?_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("YOU PAY")]),_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"},[_vm._v("YOU RECEIVE")]),_c('th',{staticClass:"text-left"},[_vm._v("EXPIRES IN")]),(!_vm.showOnlyBasicColumns)?_c('th',{staticClass:"text-left"},[_vm._v("TRADER")]):_vm._e(),_c('th',{staticClass:"text-left"},[_vm._v("ACTIONS")]),(!_vm.showOnlyBasicColumns)?_c('th',{staticClass:"text-left"},[_vm._v("DETAILS")]):_vm._e(),(!_vm.showOnlyBasicColumns)?_c('th',{staticClass:"text-left"},[_vm._v("INSPECT")]):_vm._e(),(!_vm.showOnlyBasicColumns)?_c('th',{staticClass:"text-left"},[_vm._v("CREATED")]):_vm._e()])]):_vm._e()]}},{key:"item",fn:function(ref){
    var item = ref.item;
    var isMobile = ref.isMobile;
return [(!isMobile || _vm.forceTableView)?_c('TradeItem',{key:item.tradeID,attrs:{"tradeItem":item,"showOnlyBasicColumns":_vm.showOnlyBasicColumns,"blockCurrentNumber":_vm.blockCurrentNumber,"isHistoryAllowed":_vm.isHistoryAllowed}}):_c('tr',{key:item.tradeID},[_c('TradeItemMobile',{staticClass:"mb-8",attrs:{"tradeItem":item,"blockCurrentNumber":_vm.blockCurrentNumber,"showOnlyBasicColumns":_vm.showOnlyBasicColumns,"isHistoryAllowed":_vm.isHistoryAllowed}})],1)]}}])}):_c('v-alert',{staticClass:"text-center py-8",attrs:{"type":"info","color":"primary"}},[_c('h2',[_vm._v("Nothing to show.")]),_c('v-btn',{staticClass:"my-4",attrs:{"text":"","to":{ name: 'home' }}},[_c('h2',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Create a Trade")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }