



import animal from "vue-animals";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {
    "v-animal": animal,
  },
})
export default class UserAvatar extends Vue {
  @Prop({ type: Number, default: 48, required: false })
  size!: number;
  @Prop({ type: String, default: "orange", required: false })
  color!: string;
  @Prop({ type: String, default: undefined, required: false })
  name!: string;
  @Prop({ type: String, default: null, required: false })
  address!: string | null;

  /**
   * Get animal based on address
   */
  get animal(): string {
    if (!this.address) return this.animals[0];
    const animalIndex =
      Math.abs(this.getHashedString(this.address)) % this.animals.length;
    return this.animals[animalIndex];
  }

  getHashedString(input: string): number {
    var val = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      val = (val << 5) - val + char;
      val = val & val;
    }
    return val;
  }

  get animals() {
    return ["panda", "elephant", "kraken", "koala", "dolphin", "alligator"];
  }

  get sizePx() {
    return this.size + "px";
  }
}
