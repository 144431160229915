











import { ITrade, ITradePagination } from "@/store/offers";
import { Action, Getter, Mutation } from "vuex-class";
import EthereumHelper from "@/helpers/ethereum.helper";
import { Component, Vue, Watch } from "vue-property-decorator";
import TradeList, { ITradeListOptions } from "@/components/Trade/TradeList.vue";
@Component({
  components: {
    TradeList,
  },
})
export default class ExploreView extends Vue {
  @Getter("isLoading", { namespace: "trades" })
  isLoading!: boolean;
  @Getter("trades", { namespace: "trades" })
  trades: Array<ITrade> | undefined;
  @Action("tradesGet", { namespace: "trades" })
  tradesGet!: (options?: ITradeListOptions) => any;
  @Mutation("setTrades", { namespace: "trades" }) setTrades!: (
    payload: Array<ITrade>
  ) => any;

  itemsTotal = 0;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  get contract(): any | null {
    return this.ethereumHelper?.contract ?? null;
  }

  get envPaginationPerPage(): number {
    return parseInt(process.env.VUE_APP_PAGINATION_PER_PAGE ?? "25");
  }

  mounted() {
    this.setTrades([]);
    this.fetch({
      page: 1,
      itemsPerPage: this.envPaginationPerPage,
    });
  }

  /**
   * If using Infura as a provider,
   * and Network was changed with NavigationChain component
   * - refetch data on a new network.
   */
  @Watch("contract", {
    deep: false,
    immediate: false,
  })
  contractUpdated() {
    this.setTrades([]);
    this.fetch({
      page: 1,
      itemsPerPage: this.envPaginationPerPage,
    });
  }

  async fetch(options?: ITradeListOptions) {
    const res: ITradePagination = await this.tradesGet(options);
    this.itemsTotal = res.itemsTotal?.toNumber() ?? 0;
  }
}
