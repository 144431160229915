





import { ITrade } from "@/store/offers";
import TimeAgo from "../General/TimeAgo.vue";
import EthereumHelper from "@/helpers/ethereum.helper";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TradeItemCreatedAt",
  components: {
    TimeAgo,
  },
})
export default class TradeItemCreatedAt extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;

  mounted() {
    this.setBlockCreatedTimestamp();
  }

  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  blockCreatedTimestamp: number | null = null;
  async setBlockCreatedTimestamp() {
    const block = await this.ethereumHelper.provider?.getBlock(
      this.tradeItem.blockCreated!.toNumber()
    );
    this.blockCreatedTimestamp = block?.timestamp ?? null;
  }
}
