































































































import UserAvatar from "../User/UserAvatar.vue";
import NavigationUser from "./NavigationUser.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import NavigationChain from "./NavigationChain.vue";
@Component({
  components: {
    UserAvatar,
    NavigationUser,
    NavigationChain,
  },
})
export default class Navigation extends Vue {
  @Prop({ type: String, required: false, default: "" })
  name!: string;
}
