import { ActionTree } from "vuex";
import { RootState } from "../types";
import BigNumber from "bignumber.js";
import tokensHelper from "../tokens";
import NotificationHelper, {
  NotificationType,
} from "@/helpers/notification.helper";
import { ContractReceipt } from "ethers";
import { ADDRESS_NULL } from "@/helpers/constants";
import ethereumHelper from "@/helpers/ethereum.helper";
import { ITradeDetails, ITradePagination } from "../offers";
import { ITradeListOptions } from "@/components/Trade/TradeList.vue";
import { TradesState, TradeAcceptDto, TradeCancelDto } from "./types";
import TransactionReceiptHelper from "@/helpers/transactionReceipt.helper";

export const actions: ActionTree<TradesState, RootState> = {
  async tradeGetDetailsById(
    { commit },
    id: string
  ): Promise<ITradeDetails | null> {
    commit("setIsLoading", true);
    let val: ITradeDetails | null = null;
    try {
      val = await ethereumHelper.tradeGetDetailsById(id);
      if (val) {
        commit("setTradeDetails", [val]);
      }
    } catch (error: any) {
      console.log(error);
      // Notification
      NotificationHelper.publish(
        "Error: Trade Details not Fetched",
        error,
        NotificationType.ERROR
      );
    }
    commit("setIsLoading", false);
    return val;
  },
  async tradesGet(
    { commit },
    options?: ITradeListOptions
  ): Promise<ITradePagination> {
    commit("setIsLoading", true);
    let res: ITradePagination = {};
    try {
      const page = options?.page?.toString() ?? ADDRESS_NULL;
      const perPage = options?.itemsPerPage?.toString() ?? ADDRESS_NULL;
      res = await ethereumHelper.tradesGet(page, perPage);
      const items = res.items;
      commit("setTrades", items);
    } catch (error: any) {
      // Notification
      NotificationHelper.publish(
        "Error: Trades not Fetched",
        error,
        NotificationType.ERROR
      );
    }
    commit("setIsLoading", false);
    return res;
  },
  async userTradesGet(
    { commit },
    payload: { id: string; options?: ITradeListOptions }
  ): Promise<ITradePagination> {
    commit("setIsLoading", true);
    let res: ITradePagination = {};
    try {
      const page = payload.options?.page?.toString() ?? ADDRESS_NULL;
      const perPage = payload.options?.itemsPerPage?.toString() ?? ADDRESS_NULL;
      res = await ethereumHelper.userTradesGet(payload.id, page, perPage);
      const items = res.items;
      commit("setTrades", items);
    } catch (error: any) {
      // Notification
      NotificationHelper.publish(
        "Error: Trades not Fetched",
        error,
        NotificationType.ERROR
      );
    }
    commit("setIsLoading", false);
    return res;
  },
  async tradeAccept(
    { state, getters, commit },
    payload: TradeAcceptDto
  ): Promise<BigNumber | undefined> {
    commit("setTradeIsAcceptPending", {
      tradeID: payload.trade.tradeID,
      isAcceptPending: true,
    });

    try {
      const transaction = await ethereumHelper.tradeAccept(
        payload.trade,
        payload.tokenRightSelected
      );
      transaction.wait().then((rc: ContractReceipt) => {
        // Get tradeId
        const tradeId: BigNumber =
          TransactionReceiptHelper.getEventArgumentValueFromEvents(
            rc.events,
            "TradeAccepted",
            "tradeID"
          );

        commit("setTradeIsAcceptPending", {
          tradeID: payload.trade.tradeID,
          isAcceptPending: false,
        });
        commit("tradeUpdate", {
          tradeID: payload.trade.tradeID,
          trade: {
            isActive: false,
            isCompleted: true,
          },
        });
        const addTokenToWallet = tokensHelper.getTokenByAddress(
          payload.trade.tokenLeft!
        );
        // Notification
        NotificationHelper.publish(
          "Trade Accept",
          undefined,
          NotificationType.SUCCESS,
          `/trade/${payload.trade.tradeID}`,
          "View the Trade",
          addTokenToWallet
        );
      });
    } catch (error: any) {
      // Notification
      NotificationHelper.publish(
        "Error: Trade not Accepted",
        error,
        NotificationType.ERROR
      );
      // Commit
      commit("setTradeIsAcceptPending", {
        tradeID: payload.trade.tradeID,
        isAcceptPending: false,
      });

      throw error;
    }

    return payload.trade.tradeID;
  },
  async tradeCancel(
    { state, getters, commit },
    payload: TradeCancelDto
  ): Promise<BigNumber | undefined> {
    commit("setTradeIsCancelPending", {
      tradeID: payload.tradeID,
      isCancelPending: true,
    });
    try {
      const transaction = await ethereumHelper.tradeCancel(payload.tradeID);
      transaction.wait().then((transactionReceipt: ContractReceipt) => {
        /**
         * Get tradeId
         */
        const tradeId =
          TransactionReceiptHelper.getEventArgumentValueFromEvents(
            transactionReceipt.events,
            "TradeCancelled",
            "tradeID"
          );
        commit("setTradeIsCancelPending", {
          tradeID: payload.tradeID,
          isCancelPending: false,
        });
        commit("tradeUpdate", {
          tradeID: payload.tradeID,
          trade: {
            isActive: false,
          },
        });
        NotificationHelper.publish(
          "Trade Canceled",
          payload.tradeID.toString(),
          NotificationType.SUCCESS
        );
      });
    } catch (error: any) {
      // Notification
      NotificationHelper.publish(
        "Error: Trade not Canceled",
        error,
        NotificationType.ERROR
      );
      // Commit
      commit("setTradeIsCancelPending", {
        tradeID: payload.tradeID,
        isCancelPending: false,
      });
      throw error;
    }
    return payload.tradeID;
  },
};
