



















import { Action, Getter } from "vuex-class";
import { ITokenAndAmount } from "@/store/tokens";
import TokenHeader from "@/components/Token/TokenHeader.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UserTokensHelper } from "@/store/userTokens/userTokens.helper";
@Component({
  components: {
    TokenHeader,
  },
})
export default class UserTokens extends Vue {
  @Prop({ type: String, required: true, default: undefined })
  userId!: string;

  userTokensHelper: UserTokensHelper | null = null;
  @Getter("isLoading", { namespace: "tokens" })
  listsIsLoading: boolean | undefined;
  @Action("tokensSet", { namespace: "userTokens" })
  tokensSet!: (payload: Array<ITokenAndAmount>) => any;

  @Watch("userId", {
    deep: false,
    immediate: true,
  })
  userIdChanged(valueNew: any, valueOld: any) {
    this.fetchTokenAmounts(this.userId);
  }
  @Watch("listsIsLoading", {
    deep: false,
    immediate: true,
  })
  listsIsLoadingChanged(valueNew: any, valueOld: any) {
    this.fetchTokenAmounts(this.userId);
  }

  get tokensAndAmount(): Array<ITokenAndAmount> {
    return this.userTokensHelper ? this.userTokensHelper.storeTokens : [];
  }

  fetchTokenAmounts(userId: string) {
    this.tokensSet([]);
    this.userTokensHelper = new UserTokensHelper();
    this.userTokensHelper.fetchUserTokensAndAmount(userId);
  }
}
