import chainHelper from "./chain.helper";

/**
 * Block Helper
 */
export class BlockHelper {
  /**
   * Seconds per block
   */
  defaultBlockCreationTimeSeconds = 15;

  /**
   * Default block creation time:
   * Specific for each Chain,
   * or default value.
   */
  getCurrentChainBlockTimeInSeconds(): number {
    const chainId = chainHelper.network?.chainId;
    if (!chainId) {
      return this.defaultBlockCreationTimeSeconds;
    }
    const chain = chainHelper.getChain(chainId);
    if (!chain) {
      return this.defaultBlockCreationTimeSeconds;
    }
    return chain?.blockCreationTimeSeconds;
  }

  getBlockCntSeconds(blockCnt: number): number {
    return blockCnt * this.getCurrentChainBlockTimeInSeconds();
  }
}

export default new BlockHelper();
