export enum TransactionStatus {
  APPROVE_REQUIRED = "approve_required",
  APPROVE_REJECTED = "approve_rejected",
  APPROVE_DONE = "approve_done",
  MINING = "mining",
  MINED = "mined",
}

export interface ITransaction {
  contractAddress?: string;
  contractIsErc20?: boolean;
  status?: TransactionStatus;
}

export interface TransactionsState {
  transactions?: Array<ITransaction>;
}

export interface ITransactionUpdate {
  contractAddress: string;
  transaction: ITransaction;
}
