



































































import BigNumber from "bignumber.js";
import { Action, Getter } from "vuex-class";
import { ITrade, ITradeDetails } from "@/store/offers";
import tokensHelper, { IToken } from "@/store/tokens";
import { BigNumber as BigNumberEthers } from "ethers";
import EthereumHelper from "../helpers/ethereum.helper";
import TradeItemHelper from "@/helpers/tradeItem.helper";
import TradesSuggested from "@/components/Trade/TradesSuggested.vue";
import TradeList from "@/components/Trade/TradeList.vue";
import TokenHeader from "@/components/Token/TokenHeader.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import ERC20AmountHelpers from "@/helpers/erc20Amounts.helper";
import TradeItemMobile from "../components/Trade/TradeItemMobile.vue";
import TradeListHelper from "@/helpers/tradeList.helper";
import {
  ITradeListFilterSubmit,
  TradeListFilterType,
} from "@/components/Trade/TradeListFilter.vue";
@Component({
  components: {
    TradeList,
    TokenHeader,
    TradesSuggested,
    TradeItemMobile,
  },
})
export default class TradeView extends Vue {
  @Getter("isLoading", { namespace: "trades" })
  isLoading!: boolean;
  @Action("tradeGetDetailsById", { namespace: "trades" })
  tradeGetDetailsById!: (id: string) => any;
  @Getter("getTradeDetailById", { namespace: "trades" })
  getTradeDetailById!: (id: string) => any;

  @Getter("trades", { namespace: "trades" })
  trades: Array<ITrade> | undefined;
  get tradesFiltered() {
    return TradeListHelper.filterTradeList(
      this.trades ?? [],
      this.listFilter,
      this.blockCurrentNumber ?? undefined,
      true
    );
  }
  get listFilter(): ITradeListFilterSubmit {
    if (
      this.tradeDetail?.trade?.tokensRight &&
      this.tradeDetail?.trade?.tokensRight.length
    ) {
      return {
        type: TradeListFilterType.ACTIVE,
        tokens: [
          tokensHelper.getTokenByAddress(this.tradeDetail.trade!.tokenLeft!)!,
        ],
      };
    }
    return {};
  }

  id: string | null = null;
  network: any | null = null;
  blockCurrentNumber: number | null = null;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  get contract(): any | null {
    return this.ethereumHelper?.contract ?? null;
  }
  get feeHeading(): string | null {
    if (!this.tradeDetail?.trade) return null;
    if (TradeItemHelper.isAccepted(this.tradeDetail?.trade)) {
      return "Fee taken from Trade Creator";
    } else if (this.tradeDetail?.trade?.isActive) {
      return "Fee to be taken from Trade Creator";
    }
    return null;
  }

  async setBlockCurrentNumber() {
    this.blockCurrentNumber =
      (await this.ethereumHelper.provider?.getBlockNumber()) ?? null;
  }

  mounted() {
    this.id = this.$route.params.id;
    this.tradeGetDetailsById(this.id);
    this.fetchEventUrls();
    this.setBlockCurrentNumber();
  }

  /**
   * If using Infura as a provider,
   * and Network was changed with NavigationChain component
   * - refetch data on a new network.
   */
  @Watch("contract", {
    deep: false,
    immediate: false,
  })
  contractUpdated() {
    this.tradeGetDetailsById(this.id!);
    this.createdEventUrl = null;
    this.acceptedEventUrl = null;
    this.cancelledEventUrl = null;
    this.fetchEventUrls();
  }

  get tradeDetail(): ITradeDetails | null {
    if (!this.id) return null;
    return this.getTradeDetailById(this.id) ?? null;
  }
  get formattedFee(): BigNumber | null {
    if (!this.tradeDetail || !this.tradeDetail.fee) return null;
    if (!this.tradeDetail.trade?.tokenLeft) return null;
    return ERC20AmountHelpers.transformAmountToTokensBasedOnDecimals(
      new BigNumber(this.tradeDetail.fee.toString()),
      this.tokenLeft?.decimals ?? 18
    );
  }
  get tokenLeft(): IToken | null {
    if (
      !this.tradeDetail ||
      !this.tradeDetail.trade ||
      !this.tradeDetail.trade.tokenLeft
    )
      return null;
    return this.getTokenByAddress(this.tradeDetail.trade?.tokenLeft) ?? null;
  }

  getTokenByAddress(val: string): IToken | undefined {
    return tokensHelper.getTokenByAddress(val);
  }

  /**
   * Events
   */

  createdEventUrl: string | null = null;
  acceptedEventUrl: string | null = null;
  cancelledEventUrl: string | null = null;
  async fetchEventUrls() {
    const id = BigNumberEthers.from(this.id!);
    this.createdEventUrl = await TradeItemHelper.getCreatedExplorerLink(id);
    this.acceptedEventUrl = await TradeItemHelper.getAcceptedExplorerLink(id);
    this.cancelledEventUrl = await TradeItemHelper.getCancelledExplorerLink(id);
  }
}
