export interface ITool {
  name_short?: string;
  name_long?: string;
  avatar_url?: string;
}

/**
 * List
 */
export const tools: Array<ITool> = [
  {
    name_short: "pazar",
    name_long: "Pazar",
    avatar_url: "/img/theme/logo.svg",
  },
  // {
  //   name_short: "US",
  //   name_long: "Uniswap",
  //   avatar_url: "/img/tokens/usd-coin.png",
  // },
];

export default tools;
