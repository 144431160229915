
























import { IToken } from "@/store/tokens";
import TokenSelectList from "./TokenSelectList.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TokenSelectDialog",
  components: {
    TokenSelectList,
  },
})
export default class TokenSelectDialog extends Vue {
  @Prop({ type: Array, required: true, default: undefined })
  tokens!: Array<IToken>;
  @Prop({ type: Object, required: false, default: null })
  selected!: IToken | null;

  dialog = true;

  submit(val: IToken | null) {
    this.$emit("selected", val);
  }
  close() {
    this.dialog = false;
    this.$emit("close");
  }
}
