import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { RootState } from "./types";
import { user } from "./user/index";
import { userTokens } from "./userTokens/index";
import { tokens } from "./tokens/index";
import { trades } from "./trades/index";
import { chains } from "./chains/index";
import { transactions } from "./transactions/index";
import { notifications } from "./notifications/index";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0",
  },
  modules: {
    notifications,
    transactions,
    user,
    userTokens,
    trades,
    chains,
    tokens,
  },
};

export default new Vuex.Store<RootState>(store);
