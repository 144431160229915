





































import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    //
  },
})
export default class TokenListImport extends Vue {
  @Action("fetchTokenList", { namespace: "tokens" })
  fetchTokenList!: (address: string) => any;
  @Getter("isLoading", { namespace: "tokens" })
  isLoading!: boolean;

  newListUrl: string | null = null;
  newListUrlRules = [
    (v: any) => !v || this.isValidUrl(v) || "Must be valid URL",
  ];

  async addListByUrl(newListUrl: string | null) {
    if (!newListUrl) return;
    if (!this.isValidUrl(newListUrl)) return;

    await this.fetchTokenList(newListUrl);
  }

  isValidUrl(v: string | null) {
    if (!v) return false;
    try {
      new URL(v);
      return true;
    } catch (error) {
      return false;
    }
  }
}
