










import { Component, Vue } from "vue-property-decorator";
import TradeCreate from "@/components/Trade/TradeCreate.vue";
@Component({
  components: {
    TradeCreate,
  },
})
export default class HomeView extends Vue {
  //
}
