
























export enum AmountTypeEnum {
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
  YEARS = "years",
}
import blockHelper from "@/helpers/block.helper";
import SecondsToHMS from "../General/SecondsToHMS.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "ExpiresInInput",
  components: {
    SecondsToHMS,
  },
})
export default class ExpiresInInput extends Vue {
  amountVal: number | null = null;
  amountValRules = [
    (v: any) => !!v || "Amount is required",
    (v: any) => (!!v && v > 0) || "Amount must be greater than 0",
  ];

  amountTypeVal: string | null = AmountTypeEnum.MINUTES;
  amountTypeValList: Array<string> = Object.values(AmountTypeEnum);

  /**
   * Emit change
   */
  @Watch("amountVal")
  amountValChanged(newValue: number) {
    this.emitData();
  }
  @Watch("amountTypeVal")
  amountTypeValChanged(newValue: string) {
    this.emitData();
  }
  emitData() {
    const blocks = this.convertAmountToBlocks();
    if (!blocks) return;
    this.$emit("update", blocks);
  }

  getSecondsFromAmount(amount: number, amountType: string): number {
    let amountTypeSeconds = 1;
    switch (amountType) {
      case AmountTypeEnum.MINUTES:
        amountTypeSeconds = 60;
        break;
      case AmountTypeEnum.HOURS:
        amountTypeSeconds = 60 * 60;
        break;
      case AmountTypeEnum.DAYS:
        amountTypeSeconds = 24 * 60 * 60;
        break;
      case AmountTypeEnum.WEEKS:
        amountTypeSeconds = 7 * 24 * 60 * 60;
        break;
      case AmountTypeEnum.MONTHS:
        amountTypeSeconds = 30 * 24 * 60 * 60;
        break;
      case AmountTypeEnum.YEARS:
        amountTypeSeconds = 365 * 24 * 60 * 60;
        break;
    }
    return amount * amountTypeSeconds;
  }

  /**
   * Calculations
   */
  convertAmountToBlocks(): number | null {
    if (!this.amountVal) return null;
    if (!this.amountTypeVal) return null;

    const blockTimeInSeconds = blockHelper.getCurrentChainBlockTimeInSeconds();
    const amountInSeconds = this.getSecondsFromAmount(
      this.amountVal,
      this.amountTypeVal
    );

    /**
     * Rounding down,
     * Expiring before, if we can't get a whole number for the block
     */
    const blocks = Math.floor(amountInSeconds / blockTimeInSeconds);
    return blocks;
  }
}
