
















































import TokenBox from "./TokenBox.vue";
import { IToken } from "@/store/tokens";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    TokenBox,
  },
})
export default class TokenHeaderHorizontal extends Vue {
  @Prop({ type: Object, required: true, default: undefined })
  token!: IToken;
  @Prop({ type: Boolean, required: false, default: true })
  isAvatarVisible!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isNameVisible!: boolean;
  @Prop({ type: Number, required: false, default: undefined })
  amount!: number;
  @Prop({ type: Boolean, required: false, default: true })
  isAmountInBadge!: boolean;
  @Prop({ type: Number, required: false, default: 64 })
  size!: number;
  @Prop({ type: Boolean, required: false, default: true })
  isEditable!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isRemoveVisible!: boolean;

  @Ref() readonly amountInput!: any;

  amountLocal: string | null = null;

  focusAmount() {
    if (!this.isEditable) return;
    (this.$refs.amountInput as any)?.focus();
  }

  remove() {
    this.$emit("remove");
  }
  edit() {
    this.$emit("edit");
  }

  @Watch("amount", {
    deep: true,
    immediate: true,
  })
  amountChanged(newValue: string) {
    if (newValue === undefined || newValue === null) {
      this.amountLocal = null;
    }
    if (this.amountLocal !== newValue.toString()) {
      this.amountLocal = newValue.toString();
    }
  }

  @Watch("amountLocal", {
    deep: true,
    immediate: true,
  })
  amountLocalChanged(newValue: string) {
    const isValid = this.correctInput(newValue);
    if (isValid) {
      this.submit(newValue);
    }
  }

  /**
   * Fix errors
   * Dots, Commas
   */
  correctInput(val: string): boolean {
    if (!val || !val.length) {
      return false;
    }

    let dotCnt = 0;
    let isValid = true;
    let resultingVal = "";
    for (const char of val) {
      if (char === ".") {
        dotCnt++;
        if (dotCnt < 2) {
          resultingVal += char;
        } else {
          isValid = false;
        }
      } else if (char >= "0" && char <= "9") {
        resultingVal += char;
      } else {
        isValid = false;
      }
    }

    this.$nextTick(() => {
      this.amountLocal = resultingVal;
    });

    return isValid;
  }

  /**
   * Amount
   */
  amountRules = [
    (v: any) => !!v || "Amount is required",
    (v: any) => (!!v && v > 0) || "Amount must be greater than 0",
  ];
  submit(value: string) {
    this.$emit("set:amount", parseFloat(value));
  }
}
