import { BigNumber } from "ethers";
import { ITrade } from "@/store/offers";
import chainHelper from "./chain.helper";
import { ADDRESS_NULL } from "./constants";
import blockHelper from "@/helpers/block.helper";
import EventsHelper from "@/helpers/events.helper";

export interface IExpiresAtTime {
  seconds: number;
  isPast: boolean;
}

export class TradeItemHelper {
  static getExplorerPrefix(): string | null {
    const network = chainHelper.network;
    if (!network || !network.chainId) return null;
    const chain = chainHelper.getChain(network?.chainId);
    if (!chain) return null;
    return chain.explorerUrlTransactionPrefix ?? null;
  }
  static async getCreatedExplorerLink(id: BigNumber) {
    const createdEventTx = await EventsHelper.getTradeCreatedEventTx(id);
    const hash = createdEventTx ? createdEventTx.hash : null;
    return hash ? this.getExplorerPrefix() + hash : null;
  }
  static async getAcceptedExplorerLink(id: BigNumber) {
    const createdEventTx = await EventsHelper.getTradeAcceptedEventTx(id);
    const hash = createdEventTx ? createdEventTx.hash : null;
    return hash ? this.getExplorerPrefix() + hash : null;
  }
  static async getCancelledExplorerLink(id: BigNumber) {
    const createdEventTx = await EventsHelper.getTradeCancelledEventTx(id);
    const hash = createdEventTx ? createdEventTx.hash : null;
    return hash ? this.getExplorerPrefix() + hash : null;
  }
  static isCreator(tradeItem: ITrade, signerAddress?: string): boolean {
    if (!signerAddress) return false;
    return tradeItem.userCreator === signerAddress;
  }
  static isAccepted(tradeItem: ITrade) {
    return !tradeItem.isActive && tradeItem.isCompleted;
  }
  static isCanceled(tradeItem: ITrade) {
    return !tradeItem.isActive && !tradeItem.isCompleted;
  }
  static isExpired(tradeItem: ITrade, blockCurrentNumber?: number) {
    if (!tradeItem) return false;
    if (!blockCurrentNumber) return false;
    if (!tradeItem.blockCreated) return false;
    if (!tradeItem.blockExpiresIn?.toNumber()) return false;
    return (
      blockCurrentNumber >
      tradeItem.blockCreated.toNumber() + tradeItem.blockExpiresIn.toNumber()
    );
  }
  static getExpiresAtTime(
    tradeItem: ITrade,
    blockCurrentNumber?: number
  ): IExpiresAtTime | null {
    // Doesn't expire.
    if (tradeItem.blockExpiresIn?.toString() === ADDRESS_NULL) {
      return null;
    }

    // Get Block expires in
    const blockExpiresIn = tradeItem.blockExpiresIn?.toNumber();
    if (!blockExpiresIn) return null;

    // Get Block Created
    const blockCreated = tradeItem.blockCreated?.toNumber();
    if (!blockCreated) return null;

    // Get Block Current
    const blockCurrent = blockCurrentNumber;
    if (!blockCurrent) return null;

    // Calculate
    const blockExpiresAt = blockCreated + blockExpiresIn;
    const isExpired = blockExpiresAt < blockCurrent;

    const expiresInSeconds = blockHelper.getBlockCntSeconds(
      Math.abs(blockCurrent - blockExpiresAt)
    );

    return { seconds: expiresInSeconds, isPast: isExpired };
  }
}

export default TradeItemHelper;
