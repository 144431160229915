import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { RootState } from "../types";
import { mutations } from "./mutations";
import { NotificationsState } from "./types";

export const state: NotificationsState = {
  notifications: [],
};

const namespaced = true;

export const notifications: Module<NotificationsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
