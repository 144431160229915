import { ITool } from "./tools";
import { ITokenAndAmount } from "./tokens";

/**
 * Trades
 *
 * Interfaces
 */

/**
 * TradeCreate Interfaces
 * Overall, Required, Optional
 */

export enum TradeTokenSide {
  LEFT = "left",
  RIGHT = "right",
}
export interface ITradeCreateSubmit {
  required?: ITradeCreateRequiredSubmit;
  optional?: ITradeCreateOptionalSubmit;
}
export interface ITradeCreateRequiredSubmit {
  giveTokens?: Array<ITokenAndAmount>;
  receiveTokens?: Array<ITokenAndAmount>;
}
export interface ITradeCreateOptionalSubmit {
  tool?: ITool;
  expireIn?: number;
  targetAddress?: string;
}
