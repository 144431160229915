


























import { Getter } from "vuex-class";
import { IToken } from "@/store/tokens";
import { INotificationItem } from "@/helpers/notification.helper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import WalletAssetsHelper from "@/helpers/walletAssets.helper";
@Component({
  //
})
export default class Notification extends Vue {
  @Prop({ type: Object, required: true, default: {} })
  item!: INotificationItem;
  @Prop({ type: Function, required: true, default: undefined })
  close!: any;

  @Getter("notification", { namespace: "notifications" })
  notificationGetter!: (id: number) => any;

  get notification() {
    if (!this.item) return null;
    if (!this.item.data?.id) return null;
    return this.notificationGetter(this.item.data?.id);
  }

  @Watch("notification", {
    deep: true,
    immediate: true,
  })
  notificationStoreChanged(newValue: INotificationItem) {
    if (newValue.data?.duration === 0) this.close();
  }

  get linkUrl() {
    return this.item.data?.linkUrl;
  }
  get linkName() {
    return this.item.data?.linkName;
  }
  get tokenToAdd(): IToken | undefined {
    return this.item.data?.addTokenToWallet;
  }

  /**
   * Add Token To Wallet
   */
  addTokenToWallet() {
    if (!this.tokenToAdd) return;
    WalletAssetsHelper.addTokenToWallet(this.tokenToAdd);
  }
}
