













































import { IToken } from "@/store/tokens";
import TokenHeader from "@/components/Token/TokenHeader.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    TokenHeader,
  },
})
export default class TokenSelect extends Vue {
  @Prop({ type: Array, required: true, default: undefined })
  tokens!: Array<IToken>;
  @Prop({ type: Array, required: false, default: undefined })
  tokensSelected!: Array<IToken>;
  @Prop({ type: String, required: false, default: "Select Tokens" })
  label!: string;
  @Prop({ type: Boolean, required: false, default: false })
  isDense!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isLightTheme!: boolean;

  get selectionItemSize() {
    return this.isDense ? 24 : 32;
  }

  filterItems(item: IToken, queryText: string, itemText: string): boolean {
    const queryTextLowerCase = queryText.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(queryTextLowerCase) > -1 ||
      item.symbol.toLowerCase().indexOf(queryTextLowerCase) > -1
    );
  }

  remove(token: IToken) {
    const index = this.tokensSelectedLocal.findIndex((item: IToken) => {
      return item.address === token.address;
    });
    if (index >= 0) this.tokensSelectedLocal.splice(index, 1);
  }

  /**
   * Tokens Selected
   */
  tokenRules = [(v: any) => !!v || "Token is required"];
  tokensSelectedLocal: Array<IToken> = [];
  @Watch("tokensSelectedLocal", {
    deep: true,
    immediate: false,
  })
  tokensSelectedLocalChanged() {
    this.emitSelected();
  }

  /**
   * Emit Selected
   */
  emitSelected() {
    this.$emit("selected", this.tokensSelectedLocal);
  }
}
