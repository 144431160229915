import { Event } from "ethers";

/**
 * TransactionReceipt Helper
 */
export class TransactionReceiptHelper {
  /**
   * Get Event Argument Value from Events
   */
  static getEventArgumentValueFromEvents(
    events: Array<Event> | undefined,
    eventName: string,
    argumentName: string
  ): any | undefined {
    if (!events) return undefined;
    const event = this.getEvent(events, eventName);
    if (!event) return undefined;
    return this.getEventArgumentValue(event, argumentName);
  }

  /**
   * Get Event Argument Value
   */
  static getEventArgumentValue(
    event: Event,
    argumentName: string
  ): any | undefined {
    if (!event) return undefined;
    if (!event.args) return undefined;
    return event.args[argumentName];
  }

  /**
   * Get Event
   */
  static getEvent(events: Array<Event>, eventName: string): Event | undefined {
    if (!events) return undefined;
    return events.find((event) => event.event === eventName);
  }
}

export default TransactionReceiptHelper;
