import {
  ITradeListFilterSubmit,
  TradeListFilterType,
} from "@/components/Trade/TradeListFilter.vue";
import { ITrade } from "@/store/offers";
import TradeItemHelper from "./tradeItem.helper";

export class TradeListHelper {
  static filterTradeList(
    list: Array<ITrade>,
    filter: ITradeListFilterSubmit,
    blockCurrentNumber?: number,
    filterOnlyLeftTokens = false
  ) {
    return list.filter((trade: ITrade) => {
      if (!filter) {
        return true;
      }

      let isValid = true;
      if (filter.type) {
        if (filter.type === TradeListFilterType.ACTIVE) {
          isValid = trade.isActive ?? false;
        } else if (filter.type === TradeListFilterType.EXPIRED) {
          isValid = TradeItemHelper.isExpired(trade, blockCurrentNumber!);
        }
      }

      if (filter.tokens && filter.tokens.length) {
        /**
         * Get token Address List
         * To Filter upon later
         */
        let tradeTokenAddressList: Array<string> = [
          ...(trade.tokenLeft ? [trade.tokenLeft] : []),
        ];
        if (!filterOnlyLeftTokens) {
          tradeTokenAddressList = [
            ...tradeTokenAddressList,
            ...(trade.tokensRight ?? []),
          ];
        }

        /**
         * Filter
         */
        let isFound = false;

        for (const filterToken of filter.tokens!) {
          const foundIndex = tradeTokenAddressList.findIndex(
            (tradeTokenAddress: string) => {
              return filterToken?.address === tradeTokenAddress;
            }
          );
          if (foundIndex !== -1) {
            isFound = true;
            break;
          }
        }

        // Is Found
        if (!isFound) {
          isValid = false;
        }
      }

      return isValid;
    });
  }
}

export default TradeListHelper;
