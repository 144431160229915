import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { RootState } from "../types";
import { TokensState } from "./types";
import { mutations } from "./mutations";

export const state: TokensState = {
  isLoading: false,
  lists: [],
  tokens: [],
};

const namespaced = true;

export const tokens: Module<TokensState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
