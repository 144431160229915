












import UserConnect from "../User/UserConnect.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "DialogWalletConnect",
  components: {
    UserConnect,
  },
})
export default class DialogWalletConnect extends Vue {
  @Prop({
    type: String,
    required: false,
    default: "Connect your Wallet, to proceed.",
  })
  text!: string;

  dialog = true;

  no() {
    this.$emit("no");
  }
  yes() {
    this.$emit("yes");
  }
}
