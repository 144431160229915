







































































import { IToken } from "@/store/tokens";
import ethereumHelper from "@/helpers/ethereum.helper";
import TokenSelectListItem from "./TokenSelectListItem.vue";
import TokenHeader from "@/components/Token/TokenHeader.vue";
import TokenListsDialog from "../TokenLists/TokenListsDialog.vue";
import userTokensHelper from "@/store/userTokens/userTokens.helper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TokenImportDialog from "@/components/Token/TokenImportDialog.vue";
@Component({
  components: {
    TokenHeader,
    TokenListsDialog,
    TokenImportDialog,
    TokenSelectListItem,
  },
})
export default class TokenSelectList extends Vue {
  @Prop({ type: Array, required: true, default: undefined })
  tokens!: Array<IToken>;
  @Prop({ type: Object, required: false, default: null })
  selected!: IToken | null;
  @Prop({ type: String, required: false, default: "Select a Token" })
  label!: string;
  @Prop({ type: Boolean, required: false, default: false })
  isDense!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isLightTheme!: boolean;

  search: string | null = null;
  selectedLocalAddress: string | null = null;
  isTokenListsDialogVisible = false;
  isTokenImportDialogVisible = false;

  isSelected(token: IToken) {
    return this.selectedLocalAddress == token.address;
  }
  getIndexTotal(pageIndex: number) {
    return (this.page - 1) * this.perPage + pageIndex;
  }

  page = 1;
  perPage = 10;
  get pages() {
    return Math.ceil(this.tokensFiltered.length / this.perPage);
  }
  get signerAddress() {
    return ethereumHelper.signerAddress;
  }

  mounted() {
    this.selectedLocalAddress =
      this.findInTokens(this.selected?.address)?.address ?? null;
  }

  tokensList: Array<IToken> = [];
  get tokensFiltered() {
    // Filter
    let tokens = this.tokens.filter((token) => {
      // Nothing to search for - Return full list
      if (!this.search) return true;
      return (
        token.symbol?.toLowerCase().includes(this.search!.toLowerCase()) ||
        token.name?.toLowerCase().includes(this.search!.toLowerCase())
      );
    });
    return tokens;
  }
  setTokensList(): Array<IToken> {
    const tokens = this.tokensFiltered;

    // Paginate
    this.tokensList = tokens.slice(
      (this.page - 1) * this.perPage,
      this.page * this.perPage
    );
    return this.tokensList;
  }

  findInTokens(add?: string | null): IToken | undefined {
    if (!add) return undefined;
    return this.tokens.find((item) => item.address == add);
  }

  @Watch("signerAddress", {
    deep: true,
    immediate: true,
  })
  signerAddressChanged(newValue: string) {
    // Reset user tokens
    userTokensHelper.storeTokensSet([]);
    if (newValue) {
      userTokensHelper.fetchUserTokensAndAmount(newValue);
    }
  }
  @Watch("tokens", {
    deep: true,
    immediate: true,
  })
  tokensChanged() {
    this.setTokensList();
  }
  @Watch("search", {
    deep: true,
    immediate: false,
  })
  searchChanged() {
    /**
     * Always move to first page,
     * if search changed.
     */
    this.page = 1;

    this.setTokensList();
  }
  @Watch("page", {
    deep: true,
    immediate: false,
  })
  pageChanged() {
    this.setTokensList();
  }
  @Watch("perPage", {
    deep: true,
    immediate: false,
  })
  perPageChanged() {
    this.setTokensList();
  }
  @Watch("selectedLocalAddress", {
    deep: true,
    immediate: false,
  })
  selectedLocalAddressChanged() {
    if (
      this.selectedLocalAddress &&
      this.selectedLocalAddress !== this.selected?.address
    ) {
      this.$emit("selected", this.findInTokens(this.selectedLocalAddress));
    }
  }
}
