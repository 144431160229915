






































import { BigNumber } from "bignumber.js";
import { IToken } from "@/store/tokens";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class TokenHeader extends Vue {
  @Prop({ type: Object, required: true, default: undefined })
  token!: IToken;

  @Prop({ type: Boolean, required: false, default: true })
  isAvatarVisible!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isNameVisible!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  isNameLongVisible!: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  isAmountVisible!: boolean;
  @Prop({ type: BigNumber, required: false, default: undefined })
  amount!: BigNumber;
  @Prop({ type: Boolean, required: false, default: true })
  isAmountInBadge!: boolean;

  @Prop({ type: Number, required: false, default: 64 })
  size!: number;

  get amountLocal(): BigNumber {
    return this.amount ? this.amount : new BigNumber("0");
  }
  get name(): string {
    return this.token.symbol ?? this.token.name ?? "N/A";
  }
  get imageUrl() {
    if (this.isIpfsImage)
      return "https://gateway.ipfs.io/ipfs/" + this.token.logoURI.slice(7);
    return this.token.logoURI ?? "/img/tokens/erc201.png";
  }
  get isIpfsImage() {
    const prefix = this.token.logoURI?.substring(0, 7);
    if (prefix === "ipfs://") return true;
    return false;
  }
}
