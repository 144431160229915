export enum ChainSwitcherStatus {
  SUCCESS = "Chain changed.",
  USER_REJECTED = "User rejected Network switch. Please switch the Network manually.",
  ALLOW_TO_ADD_NETWORK = "Please allow this site to add a Network, in your Wallet.",
  REQUIRES_USER_ACTION = "Please check your Wallet and allow this site to switch the Network.",
  PENDING_REQUIRES_USER_ACTION = "Network Switch pending. Please check your Wallet and allow this site to switch the Network.",
  FAILED_CANNOT_ADD_CHAIN = "Cannot add Chain to your Wallet. Please add it manually.",
}

export interface ChainsState {
  chainSwitcherStatus?: ChainSwitcherStatus;
}
