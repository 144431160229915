

























import { ITrade } from "@/store/offers";
import { BigNumber } from "bignumber.js";
import TokenHeader from "../Token/TokenHeader.vue";
import tokensHelper, { IToken } from "@/store/tokens";
import { Component, Prop, Vue } from "vue-property-decorator";
import ERC20AmountHelpers from "@/helpers/erc20Amounts.helper";
@Component({
  name: "TradeItemTokenLeft",
  components: {
    TokenHeader,
  },
})
export default class TradeItemTokenLeft extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;

  get isActive() {
    return !!this.tradeItem.isActive;
  }

  get isCompleted() {
    return !!this.tradeItem.isCompleted;
  }

  getTokenByAddress(val: string): IToken | undefined {
    return tokensHelper.getTokenByAddress(val);
  }

  getTokenAmount(token: IToken, amount: BigNumber): BigNumber {
    return ERC20AmountHelpers.transformAmountToTokensBasedOnDecimals(
      new BigNumber(amount.toString()),
      token.decimals
    );
  }
}
