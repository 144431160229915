import { GetterTree } from "vuex";
import { RootState } from "../types";
import { ChainsState, ChainSwitcherStatus } from "./types";

export const getters: GetterTree<ChainsState, RootState> = {
  chainSwitcherStatus(state): ChainSwitcherStatus | undefined {
    const { chainSwitcherStatus } = state;
    return chainSwitcherStatus;
  },
};
