import { ActionTree } from "vuex";
import { RootState } from "../types";
import { UserTokensState } from "./types";
import { ITokenAndAmount } from "../tokens";

export const actions: ActionTree<UserTokensState, RootState> = {
  tokensSet({ commit }, payload: Array<ITokenAndAmount>): any {
    commit("tokensSet", payload);
  },
  tokenAdd({ commit }, payload: ITokenAndAmount): any {
    commit("tokenAdd", payload);
  },
  tokenUpdate({ commit }, payload: ITokenAndAmount): any {
    commit("tokenUpdate", payload);
  },
};
