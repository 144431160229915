












import { ethers } from "ethers";
import { Mutation } from "vuex-class";
import NotificationHelper, {
  NotificationType,
} from "@/helpers/notification.helper";
import EthereumHelper from "@/helpers/ethereum.helper";
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class UserConnect extends Vue {
  address: string | null = null;
  @Mutation("setSigner", { namespace: "user" })
  setSigner!: (signer: ethers.providers.JsonRpcSigner | undefined) => any;

  async connect() {
    const res = await EthereumHelper.loginAndGetAccountAddress();
    this.setSigner(EthereumHelper.signer);
    if (!res) return;
    this.handleAuthenticationSuccess(res);
  }

  handleAuthenticationSuccess(address: string) {
    this.address = address;
    NotificationHelper.publish(
      "User Authenticated",
      JSON.stringify(address),
      NotificationType.SUCCESS
    );
    this.emitAuthenticatedAccountAddress(address);
  }

  emitAuthenticatedAccountAddress(val: string) {
    this.$emit("authenticated:accountAddress", val);
  }
}
