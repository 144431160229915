














import { IToken } from "@/store/tokens";
import TokenHeader from "@/components/Token/TokenHeader.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import userTokensHelper from "@/store/userTokens/userTokens.helper";
@Component({
  components: {
    TokenHeader,
  },
})
export default class TokenSelectListItem extends Vue {
  @Prop({ type: Object, required: true, default: undefined })
  token!: IToken;
  @Prop({ type: Boolean, required: false, default: false })
  isDense!: boolean;
  @Prop({ type: Number, required: false, default: 1 })
  page!: number;
  @Prop({ type: Number, required: false, default: 0 })
  pageIndex!: number;
  @Prop({ type: Number, required: false, default: 1 })
  perPage!: number;

  get indexTotal() {
    return (this.page - 1) * this.perPage + this.pageIndex;
  }

  get selectionItemSize() {
    return this.isDense ? 24 : 32;
  }

  get userTokens() {
    return userTokensHelper.storeTokens;
  }
  get userToken() {
    return (
      this.userTokens.find(
        (item) => item.token?.address === this.token.address
      ) ?? null
    );
  }
  get userTokenAmount() {
    return this.userToken?.amount ?? null;
  }
}
