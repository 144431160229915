import { ActionTree } from "vuex";
import { IToken } from "../tokens";
import { RootState } from "../types";
import { TokensState } from "./types";
import NotificationHelper, {
  NotificationType,
} from "@/helpers/notification.helper";
import tokenListsHelper, { ITokenList } from "../tradeLists/tokenLists";

export const actions: ActionTree<TokensState, RootState> = {
  async fetchTokenLists({ commit }): Promise<Array<ITokenList>> {
    commit("setIsLoading", true);
    let items: Array<ITokenList> = [];
    try {
      items = await tokenListsHelper.fetchLists();
      if (items) {
        for (const item of items) {
          commit("addList", item);
        }
      }
    } catch (error: any) {
      // Notification
      NotificationHelper.publish(
        "Error: Lists not Fetched",
        error,
        NotificationType.ERROR
      );
    }
    commit("setIsLoading", false);
    return items;
  },
  async fetchTokenList(
    { commit },
    address: string
  ): Promise<ITokenList | null> {
    commit("setIsLoading", true);
    let item: ITokenList | null = null;
    try {
      item = await tokenListsHelper.fetchListByURL(address);
      if (item) {
        commit("addList", {
          jsonURI: address,
          name: item.name,
          logoURI: item.logoURI,
          tokens: item.tokens,
          isActive: true,
        });
      }
    } catch (error: any) {
      // Notification
      NotificationHelper.publish(
        "Error: List not Fetched",
        error,
        NotificationType.ERROR
      );
    }
    commit("setIsLoading", false);
    return item;
  },
  setTokenListIsActive({ commit }, payload: { id: number; val: boolean }): any {
    commit("setListIsActive", {
      id: payload.id,
      isActive: payload.val,
    });
  },
  addListToken({ commit }, payload: { listId: number; token: IToken }): any {
    commit("addListToken", {
      listId: payload.listId,
      token: payload.token,
    });
  },
};
