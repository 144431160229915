import Vue from "vue";
import store from "@/store";
import { IToken } from "@/store/tokens";

/**
 * NotificationType
 */
export enum NotificationType {
  PRIMARY = "primary",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

/**
 * NotificationItem Interface
 */
export interface INotificationItemData {
  /**
   * General
   */
  id?: number;
  // Added again, inside .data
  // for us to be able to access it's value
  duration?: number;
  /**
   * Add token to Wallet
   */
  addTokenToWallet?: IToken;
  /**
   * Link
   */
  linkUrl?: string;
  linkName?: string;
}
export interface INotificationItem {
  title?: string;
  type?: NotificationType;
  text?: string;
  duration?: number;
  data?: INotificationItemData;
}

/**
 * NotificationHelper
 */
export class NotificationHelper {
  static async publish(
    title = "",
    text = "",
    type: NotificationType = NotificationType.SUCCESS,
    linkUrl?: string,
    linkName?: string,
    addTokenToWallet?: IToken,
    duration = 5000
  ): Promise<number> {
    const item = {
      title,
      text,
      type,
      duration,
      data: {
        duration,
        addTokenToWallet,
        linkUrl: linkUrl,
        linkName: linkName,
      },
    };
    const id = await store.dispatch("notifications/addNotification", item);
    Vue.notify(item);
    return id;
  }
  static close(id: number) {
    store.dispatch("notifications/closeNotification", id);
  }
}

export default NotificationHelper;
