import { IToken } from "../tokens";
import { MutationTree } from "vuex";
import { TokensState } from "./types";
import { ITokenList } from "../tradeLists/tokenLists";

export const mutations: MutationTree<TokensState> = {
  setIsLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  addList(state, payload: ITokenList) {
    payload = {
      ...payload,
      id: (state.lists?.length ?? 1) + 1,
    };
    state.lists = [...(state.lists ?? []), payload];
  },
  setListIsActive(state, payload: { id: number; isActive: boolean }) {
    state.lists = state.lists?.map((list) => {
      if (list.id === payload.id) {
        list.isActive = payload.isActive;
      }
      return list;
    });
  },
  addListToken(state, payload: { listId: number; token: IToken }) {
    state.lists = state.lists?.map((list) => {
      if (list.id === payload.listId) {
        list.tokens = [...list.tokens, payload.token];
      }
      return list;
    });
  },
  setTokens(state, payload: Array<IToken> | undefined) {
    state.tokens = payload;
  },
};
