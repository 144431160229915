import { MutationTree } from "vuex";
import { UserTokensState } from "./types";
import { ITokenAndAmount } from "../tokens";

export const mutations: MutationTree<UserTokensState> = {
  setIsLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  tokensSet(state, payload: Array<ITokenAndAmount>) {
    state.tokens = payload;
  },
  tokenAdd(state, payload: ITokenAndAmount) {
    state.tokens = [...(state.tokens ?? []), payload];
  },
  tokenUpdate(state, payload: ITokenAndAmount) {
    state.tokens = state.tokens?.map((item) => {
      if (item.token?.address === payload.token?.address) {
        return {
          ...item,
          ...payload,
        };
      }
      return item;
    });
  },
};
