



import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TimeAgo",
})
export default class TimeAgo extends Vue {
  @Prop({ type: Number, required: true, default: 0 })
  timestamp!: number;
}
