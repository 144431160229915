


























import BigNumber from "bignumber.js";
import { ITrade } from "@/store/offers";
import tools, { ITool } from "@/store/tools";
import TradeCreateRequired from "./TradeCreateRequired.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ERC20AmountHelpers from "@/helpers/erc20Amounts.helper";
import tokensHelper, { IToken, ITokenAndAmount } from "@/store/tokens";
import { Getter } from "vuex-class";
@Component({
  name: "TradeItemDialogAccept",
  components: {
    TradeCreateRequired,
  },
})
export default class TradeItemDialogAccept extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;
  @Prop({ type: Number, required: false, default: 0 })
  tokenRightIndex!: number;

  @Getter("tokens", { namespace: "tokens" })
  getterTokens!: (onlyActiveLists: boolean) => Array<IToken> | undefined;

  // Dialog
  dialog = true;

  // Sides
  leftSide: Array<ITokenAndAmount> = [];
  rightSide: Array<ITokenAndAmount> = [];

  // Data
  get tokens(): Array<IToken> | undefined {
    return this.getterTokens(false);
  }
  tools: Array<ITool> = tools;

  mounted() {
    this.setSides();
  }

  /**
   * Helpers
   */
  getTokenByAddress(val: string): IToken | undefined {
    return tokensHelper.getTokenByAddress(val);
  }
  getTokenAmount(token: IToken, amount: string): BigNumber {
    const res = ERC20AmountHelpers.transformAmountToTokensBasedOnDecimals(
      new BigNumber(amount),
      token.decimals
    );
    return res;
  }

  setSides() {
    if (!this.tradeItem.tokenLeft) return;
    if (!this.tradeItem.tokenLeftAmount) return;

    /**
     * Right
     */
    const leftToken = this.getTokenByAddress(this.tradeItem.tokenLeft);
    if (!leftToken) return;
    const leftAmount = this.getTokenAmount(
      leftToken,
      this.tradeItem.tokenLeftAmount.toString()
    ).toNumber();
    this.rightSide = [
      {
        token: leftToken,
        amount: leftAmount,
      },
    ];

    /**
     * Left
     */
    const rightIndex = this.tokenRightIndex ?? 0;
    if (!this.tradeItem.tokensRight) return;
    if (!this.tradeItem.tokensRight[rightIndex]) return;
    if (!this.tradeItem.tokensRightAmounts) return;
    if (!this.tradeItem.tokensRightAmounts[rightIndex]) return;
    const rightToken = this.getTokenByAddress(
      this.tradeItem.tokensRight[rightIndex]
    );
    if (!rightToken) return;
    const rightAmount = this.getTokenAmount(
      rightToken,
      this.tradeItem.tokensRightAmounts[rightIndex].toString()
    ).toNumber();
    this.leftSide = [
      {
        token: rightToken,
        amount: rightAmount,
      },
    ];
  }

  no() {
    this.$emit("no");
  }
  yes() {
    this.$emit("yes");
  }
}
