





























import { ITrade } from "@/store/offers";
import { BigNumber } from "bignumber.js";
import { Action, Getter } from "vuex-class";
import { TradeCancelDto } from "@/store/trades/types";
import EthereumHelper from "@/helpers/ethereum.helper";
import TradeItemHelper from "@/helpers/tradeItem.helper";
import { Component, Prop, Vue } from "vue-property-decorator";
import TradeItemDialogCancel from "./TradeItemDialogCancel.vue";
@Component({
  name: "TradeItemCancel",
  components: {
    TradeItemDialogCancel,
  },
})
export default class TradeItemCancel extends Vue {
  @Prop({ required: true, default: undefined })
  tradeItem!: ITrade;

  @Action("tradeCancel", { namespace: "trades" }) tradeCancel!: (
    payload: TradeCancelDto
  ) => any;

  isDialogCancelVisible = false;
  ethereumHelper: typeof EthereumHelper = EthereumHelper;

  get signerAddress() {
    return this.ethereumHelper.signerAddress;
  }
  get isCreator() {
    if (!this.signerAddress) return false;
    return this.tradeItem.userCreator === this.signerAddress;
  }

  @Getter("tradeIsCancelPending", { namespace: "trades" })
  tradeIsCancelPending!: (id: BigNumber) => boolean;
  get isCancelPending() {
    return this.tradeIsCancelPending(this.tradeItem.tradeID!);
  }

  get isCanceled() {
    return TradeItemHelper.isCanceled(this.tradeItem);
  }

  get isCancelDisabled() {
    if (this.isCancelPending) return true;
    if (this.isCanceled) return true;
    if (TradeItemHelper.isAccepted(this.tradeItem)) return true;
    return false;
  }

  get cancelText() {
    if (this.isCanceled) {
      return "Cancelled";
    }
    return "Cancel";
  }

  async handleCancel() {
    await this.tradeCancel({
      tradeID: this.tradeItem.tradeID!,
    });
  }
}
