import { ActionTree } from "vuex";
import { RootState } from "../types";
import { NotificationsState } from "./types";
import { INotificationItem } from "@/helpers/notification.helper";

export const actions: ActionTree<NotificationsState, RootState> = {
  async addNotification(
    { commit, state },
    item: INotificationItem
  ): Promise<number> {
    const newId = (state.notifications?.length ?? 1) + 1;
    item.data = {
      ...item.data,
      id: newId,
    };
    commit("addNotification", item);
    return newId;
  },
  closeNotification({ commit }, id: number): any {
    commit("updateNotificationData", {
      id,
      data: {
        duration: 0,
      },
    });
  },
};
