





























import { ethers } from "ethers";
import { Action, Getter } from "vuex-class";
import { CHAIN_ID_DEFAULT } from "./store/chains";
import ethereumHelper from "./helpers/ethereum.helper";
import { Component, Vue } from "vue-property-decorator";
import { ChainSwitcherStatus } from "@/store/chains/types";
import Navigation from "@/components/General/Navigation.vue";
import Notification from "./components/General/Notification.vue";
import chainHelper, { IContractDeployment } from "./helpers/chain.helper";
import ServiceWorkerUpdate from "./components/General/ServiceWorkerUpdate.vue";
import SnackbarTestingTips from "./components/General/SnackbarTestingTips.vue";
@Component({
  components: {
    Navigation,
    Notification,
    ServiceWorkerUpdate,
    SnackbarTestingTips,
  },
})
export default class App extends Vue {
  @Action("fetchTokenLists", { namespace: "tokens" })
  fetchTokenLists!: () => any;
  @Getter("chainSwitcherStatus", { namespace: "chains" })
  chainSwitcherStatus?: ChainSwitcherStatus;
  @Action("setChainSwitcherStatus", { namespace: "chains" })
  setChainSwitcherStatus!: (status: ChainSwitcherStatus) => ChainSwitcherStatus;

  private name = "Pazar";
  chainHelper: typeof chainHelper = chainHelper;

  get isNetworkSupported() {
    return this.chainSwitcherStatus === ChainSwitcherStatus.SUCCESS;
  }

  mounted() {
    this.bootstrapNetworkAndContract();
  }

  async bootstrapNetworkAndContract() {
    /**
     * Fetch Token Lists
     * Store Tokens in Vuex Store
     */
    try {
      this.fetchTokenLists();
    } catch (error: any) {
      console.error("Fetch Token Lists error:", error);
    }

    /**
     * Get and Switch to Supported Network
     * Init the contract
     */
    try {
      // Get/Switch Current Network to supported one
      const currentNetwork = await this.switchToSupportedNetwork();

      // Get Contract Deployment
      const contractDeployment = this.getContractDeployment(currentNetwork);

      /**
       * We have the deployment details for our contract.
       *
       * Meaning: user was initially on the supported network,
       * or we've switched to it.
       */
      if (contractDeployment) {
        // Init Contract
        await ethereumHelper.contractInit(
          contractDeployment.address,
          contractDeployment.abi,
          ethereumHelper.provider,
          ethereumHelper.signer
        );

        /**
         * After we've initialized our Contract
         * We can set ChainSwitcherStatus to SUCCESS,
         * signaling our App that it can show sub-views
         * (render them into router-view)
         * and thus, can use our Contract and request data from it.
         */
        this.setChainSwitcherStatus(ChainSwitcherStatus.SUCCESS);
      }
    } catch (error: any) {
      console.error("Network error", error);
    }
  }

  /**
   * Get Current Network and/or Switch to the supported Network
   *
   * @returns {Promise<ethers.providers.Network | undefined>}
   */
  async switchToSupportedNetwork(): Promise<
    ethers.providers.Network | undefined
  > {
    let currentChain = await this.chainHelper.getCurrentNetwork();

    /**
     * User is not on any chain or the user's current chain is not supported,
     * switch to Goerli.
     *
     * If the user rejects the Network change?
     * We show the message that user rejected Network switch,
     * and ask the user to change it manually.
     * On change, App will reload and init the contract with supported deployment details.
     */
    if (
      !currentChain ||
      !this.chainHelper.isChainSupported(currentChain.chainId)
    ) {
      await this.chainHelper.switchNetwork(
        this.chainHelper.getChain(CHAIN_ID_DEFAULT)!
      );
      currentChain = await this.chainHelper.getCurrentNetwork();
    }

    return currentChain;
  }

  /**
   * Get Deployment details for our Contract
   * for the current Chain
   */
  getContractDeployment(
    currentChain: ethers.providers.Network | undefined
  ): IContractDeployment | undefined {
    let deployment = undefined;
    if (currentChain) {
      deployment = this.chainHelper.getContractDeploymentPerChain(
        currentChain!.chainId
      );
    }
    return deployment;
  }
}
